/*
 
 * Description: Liste mit Suchfeld
 */

(function ($) {

    $.fn.filterlist = function (options) {

        this.each(function () {

            var that = this;

            this.filterlist = {};
            this.filterlist.settings = $.extend({

                inputClass: "form-control",
                placeholder: i18next.t('Type here to filter list items'),
                searchField: [],

            }, options);

            // Override searchField by data-attribute
            if (typeof $(this).data('searchField') !== 'undefined') {
                that.filterlist.settings.searchField = $(this).data('searchField');
            }

            // Only works for <ul> and <ol>
            if (['ul', 'ol'].indexOf($(this).prop("tagName").toLowerCase()) < 0) return;

            init();

            function init() {

                // Check for custom search field
                var customSearchField = false;
                if (that.filterlist.settings.searchField.length) {
                    if (typeof that.filterlist.settings.searchField == "string") {
                        // Search for DOM object by selector
                        $custom = $(that.filterlist.settings.searchField);
                    } else {
                        // searchField is already an object
                        $custom = that.filterlist.settings.searchField;
                    }

                    // Check if DOM object exists
                    if ($custom.length) {
                        $custom
                            .data('filterlist-target', $(that))
                            .on('keyup change', filter);
                        customSearchField = true;
                    }
                }

                // No custom search field found
                if (!customSearchField) {
                    $('<input type="text" class="' + that.filterlist.settings.inputClass + '" placeholder="' + that.filterlist.settings.placeholder + '">')
                        .data('filterlist-target', $(that))
                        .on('keyup change', filter)
                        .insertBefore($(that));
                }

            }

            function filter() {

                var filter = $(this).val().toLowerCase(),
                    re = new RegExp("(" + filter + ")", "i"),
                    $list = $(this).data('filterlist-target');

                $list.find("li").show().each(function () {
                    var match = $(this).text().match(re);
                    if (!match) {
                        $(this).hide();
                    }
                });

            }

        });

    }

}(jQuery));
