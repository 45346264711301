require('./erwingo/erwingo.form.js');
require('./erwingo/erwingo.ajaxform.js');
require('./erwingo/erwingo.arrayinput.js');
require('./erwingo/erwingo.formwatcher.js');
require('./erwingo/erwingo.ajaxlister.js');
require('./erwingo/erwingo.window.js');
require('./erwingo/erwingo.upload.js');
require('./erwingo/erwingo.dynamictab.js');
require('./erwingo/erwingo.filterlist.js');
require('./erwingo/erwingoapp.js');
require('./erwingo/erwingoapp.loadmodules.js');
require('./erwingo/erwingoapp.cookie.js');
require('./erwingo/erwingoapp.processjsonresult.js');
require('./erwingo/erwingoapp.bootstrap.js');
require('./erwingo/erwingoapp.init.js');

console.log('JS: Footer scripts loaded.');