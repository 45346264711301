/* Custom jQuery functions */

/**
 * Clear input, select and textarea
 * Expects either a parent object, or the input fields themselves in $(this)
 */
$.fn.reset = function () {
    var $inputs = this;
    if ($inputs.length == 1 && !$inputs.is(':input')) {
        $inputs = $inputs.find(':input');
    }

    $inputs.each(function () {
        switch (this.type) {
            case 'password':
            case 'text':
            case 'textarea':
            case 'file':
            case 'select-one':
            case 'select-multiple':
            case 'date':
            case 'number':
            case 'tel':
            case 'email':
            case 'hidden':
                $(this).val('');
                break;
            case 'checkbox':
            case 'radio':
                this.checked = false;
                break;
        }
    });

    return this;
}

/*
Helper functions
*/

/*
* Generate random string of specific length
*/
randomString = function (length, chars) {
    length = length || 32;
    chars = chars || '0123456789abcdef';
    let result = '';
    for (let i = length; i > 0; --i) {
        result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
}

/*
Event listeners
*/
$(document).on("click", "[data-api-window]", function () {
    var res = $(this).data("apiWindow").split("/");
    //console.log(res);
    $("<div></div>").window({
        action: res[0],
        id: res[1],
        selected: res[2],
        module: $(this).data("module"),
        extra: $(this).data("extra")
    });
});

$(document).on("click", "a", function (e) {
    if ($(this).prop("disabled")) {
        e.preventDefault();
        return false;
    }
});

function windowLoadOverlay($elem, open, text) {

    var open = typeof open != 'undefined' ? open : true;
    var text = typeof text != 'undefined' ? text : '';

    $window = $elem.closest(".window");

    if (open) {
        var $wrap = $('<div class="erwingo-form-loader-container" style="display:none;"></div>'),
            $loader = $('<div class="erwingo-form-loader"></div>'),
            $spinner = $('<i class="fa fa-refresh fa-spin erwingo-form-loader-spinner"></i>'),
            $info = text ? $('<span></span>').append(text) : "",
            $loaderElem = $wrap.append($loader.append($spinner, $info));
        $window.addClass('has-erwingo-form-loader').append($loaderElem);
        $loaderElem.fadeIn(200);
    } else {
        $window.removeClass('has-erwingo-form-loader').find(".erwingo-form-loader-container").fadeOut(200, function () {
            $(this).remove();
        });
    }
}


/* Regulärer Ajax Request */
$(document).on("click", "a[data-cl], button[data-cl]:not([type='submit'])", function (e) {

    if (!$(this).data('noPrevent')) {
        e.preventDefault();
    }

    var $elem = $(this);

    if ($(this).prop("disabled")) {
        return false;
    }

    var data = {};

    $.each($(this).data(), function (k, v) {
        if (v.substring || v.toFixed) // Check if v is not string or number
            data[k] = v;
    });

    data.return = {};

    data.return.update = "#" + $(this).closest("[data-api-listing]").attr("id");

    if (typeof data.formData != "undefined") {

        data.data = data.formData;

    }

    if (typeof data.confirm != "undefined") {

        let confirmTitle = data.confirmTitle || 'Achtung!';
        let confirmButtonText = data.confirmButtonText || 'Bestätigen';
        let cancelButtonText = data.cancelButtonText || 'Abbrechen';

        Swal.fire({

            title: confirmTitle,
            html: data.confirm,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            reverseButtons: true,
            didOpen: function (elem) {
                $(elem).find('.swal2-cancel').focus();
                $(elem).find('input').on('change', function () {
                    var name = $(this).attr('name');
                    if (!$(this).is('[type=checkbox],[type=radio]') || $(this).is(':checked')) {
                        data[name] = $(this).val();
                    }
                }).trigger('change');
            }

        }).then(function (result) {
            if (result.isConfirmed) {
                if (typeof data.secondConfirm != "undefined") {

                    confirmTitle = data.secondConfirmTitle || data.confirmTitle || 'Really sure?';
                    confirmButtonText = data.secondConfirmButtonText || data.confirmButtonText || 'Confirm';
                    cancelButtonText = data.secondConfirmCancelButtonText || data.cancelButtonText || 'Cancel';

                    Swal.fire({

                        title: confirmTitle,
                        html: data.secondConfirm,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: confirmButtonText,
                        cancelButtonText: cancelButtonText,
                        reverseButtons: true,
                        didOpen: function (elem) {
                            $(elem).find('.swal2-cancel').focus();
                            $(elem).find('input').on('change', function () {
                                var name = $(this).attr('name');
                                if (!$(this).is('[type=checkbox],[type=radio]') || $(this).is(':checked')) {
                                    data[name] = $(this).val();
                                }
                            }).trigger('change');
                        }

                    }).then(function (result) {
                        if (result.isConfirmed) {
                            doButtonRequest($elem, data);
                        }

                    });

                } else {

                    doButtonRequest($elem, data);

                }

            }
        });

    } else {

        doButtonRequest($elem, data);

    }

    function doButtonRequest($elem, data) {

        if (typeof data.confirmRemove != "undefined") {
            var target = data.confirmRemove;
            $elem.closest(target).remove();
        }

        if (typeof data.loadscreen != "undefined") {
            windowLoadOverlay($elem);
        }

        var remove = $elem.closest($elem.data("remove"));

        erwingoApp.ajax({
            data: data, success: function (response) {

                remove.remove();

                windowLoadOverlay($elem, false);

                if (response) {

                    if(typeof response["error"] !== 'undefined' && response["error"] === 1 && typeof response["error_info"]["message"] !== 'undefined') {
                        Swal.fire({title: 'Ein Fehler ist aufgetreten', text: response["error_info"]["message"] , icon: 'error', timer: 5000});
                    }

                    // Lösche Foto
                    if (typeof response.image != 'undefined') {
                        $('#p' + response.image.image_secret).attr('src', response.image.image_src + "_s.png");
                    }

                    // Get redirect from response or from data-attribues or assign false
                    var redirect = typeof response.redirect != "undefined"
                        ? response.redirect
                        : typeof data.redirect != "undefined"
                            ? data.redirect
                            : false;

                    if (redirect) {

                        if (redirect == "?") {
                            if ($elem.closest(".window").length) {
                                $elem.closest(".window").window('reload');
                            } else {
                                window.location.reload();
                            }
                        } else {
                            window.location.href = redirect;
                        }

                        return;

                    }
                }

                // Update all lists
                // $("[data-api-listing]").each(function () {
                    //$(this).apiListing('update');
                    // Muss genau definiert werden ;)
                // });

                // Update closest list to button
                $elem.closest("[data-api-listing]").apiListing('update');
            }
        });

    }

});

/*
$(document).on('shown.bs.tab', 'a[data-bs-toggle="tab"]', function (e) {
    let href = $(this).attr("href")

    if (href) {
        $(href).find(".editor,.editor-slim").each(function () {
            let _ckeditor = $(this).data("ckeditorInstance")

            if (_ckeditor && typeof _ckeditor.maximizeHeightNow === 'function') {
                _ckeditor.maximizeHeightNow();
            }
        });
    }
});
*/

$(document).on("change", "input[type='checkbox'][data-cl]", function (e) {

    var $elem = $(this);

    if ($elem.prop("disabled")) {
        return false;
    }

    var data = $elem.data();
    data.value = $elem.is(":checked") ? ($elem.val() ? $elem.val() : 1) : 0;

    //console.log("ajax checkbox", data);

    $elem.addClass("loading");
    erwingoApp.ajax({
        data: data,
        success: function (response) {
            // Update all lists
            $elem.removeClass("loading");

            if (!$("input.loading[type='checkbox'][data-cl]").length) {
                $("[data-api-listing]").each(function () {
                    $(this).apiListing('update');
                });
            }
        }
    });
});

var gridsterOption = {
    namespace: '#widgets',
    widget_base_dimensions: ['auto', 300],
    autogenerate_stylesheet: true,
    min_cols: 1,
    max_cols: 6,
    widget_margins: [10, 10],
    draggable: {
        handle: '.widget-title',
        stop: function () {
            var widgets = {};
            $("#widgets > ul > li[data-widget]").each(function () {
                widgets[$(this).data("widget")] = {
                    col: $(this).attr("data-col"),
                    row: $(this).attr("data-row"),
                    sizex: $(this).attr("data-sizex"),
                    sizey: $(this).attr("data-sizey")
                }
            });

            erwingoApp.ajax({
                data: {cl: 'Backend', fnc: 'updateWidgetPositions', widgets: widgets},
                success: function (response) {
                    //console.log("updated widget positions", response);
                }
            });
        }
    },
    // Zu buggy... wenn dann bitte auf nem anderen Branch fixen
    // resize: {
    //     enabled: true
    // }
};

//load contextMenu
var closedWidgets = {};
$(document).ready(function () {
    try {
        if (ctxMenu && logged) {
            erwingoApp.contextMenu({
                'gridsterOption': gridsterOption,
                'selector': '.h100p',
                items: {
                    "edit": {name: "Edit", icon: "fa-pencil"},
                    "cut": {name: "Cut", icon: "fa-cut"},
                    "copy": {name: "Copy", icon: "fa-copy"},
                    "paste": {name: "Paste", icon: "fa-paste"}
                }
            });
        }
    } catch (e) {
    }
});

$(window).resize(function () {
    erwingoApp.calcHeight();
});

$(document).on('click', '.close-widget', function () {

    var closeWidget = $(this).data('closeWidget');
    var widget = $(this).data('widgetData');
    var widgetData = widget.split(':');
    closedWidgets = $.extend(closedWidgets, closeWidget);
    erwingoApp.ajax({
        data: {
            module: "erwingo",
            cl: "FormAdmin",
            fnc: "closeWidget",
            widgetData: widget
        },
        success: function (response) {
            $('#widgets').find('[data-widget="' + widgetData[0] + ':' + widgetData[1] + '"]').empty();
            $('#widget-select-list').find('[name="' + widgetData[0] + ':' + widgetData[1] + '"]').prop('checked', false).trigger('change');
        }
    });
});

$(document).on("click", "[data-add]", function () {
    var target = $(this).data("add");
    var template = $('[data-template="' + target + '"]').html();
    var random = (Math.random() + 1).toString(36).substring(7);

    template = erwingoApp.genText(template, {random: random});

    $(template).appendTo('[data-list="' + target + '"]');
});

$(document).on("click", "[data-remove]", function () {

    var target = $(this).data("remove");

    $(this).closest(target).remove();

});

$(document).on("click", "[data-action]", function () {

    var data = $(this).data();

    erwingoApp.ajax({data: data});

});

$(document).on("click", "[data-dialog]", function () {

    var data = $(this).data("dialog");

    var module = $(this).data("module");

    erwingoApp.ajax({
        data: {
            module: module,
            cl: 'Dialog',
            data: data
        }
    });

});

try {

    $("#widgets > ul").gridster(gridsterOption).data('gridster');

} catch (e) {
}

$(function () {

    console.log("Start JS");

    erwingoApp.loadModules(document);

    erwingoApp.calcHeight();

    /*erwingoApp.checkUser("employee", 15000);*/

});


// show warning if user wants to add an employee with defined leaving date
$(document).on('select2:selecting', 'select[data-auto-complete]', function (event) {
  const data = event.params.args.data;
  if (data.employee_leaving_date && data.employee_leaving_date !== erwingoApp.EMPTYDATE) {
      Swal.fire({
          title: i18next.t('Danger!'),
          icon: 'warning',
          html: i18next.t('<p>A leaving date has been defined for the selected employee. Check whether an assignment is really desired.</p>')
      });
  }
});
