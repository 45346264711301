(function ($) {

    $.widget("ui.apiForm", {

        version: "1",
        options: {

            prefix: '',
            suffix: '',

            autoSave: false,
            autoSaveBlur: false,
            state: 'edit',

            redirect: false,

            icons: {
                error: "fa fa-times",
                success: "fa fa-check",
                loading: "fa fa-refresh fa-spin",
                edit: "fa fa-pencil",
                ok: "",
            },

            verbose: false,

            onSuccess: function (obj, data) {
            },
            onRedirect: false,

            warnMe: "Änderungen gehen verloren! Fortfahren?",

            loaderContainer: "", // Parent of loader overlay, which prevents interaction until request has finished

        },

        _destroy: function () {

            var that = this;

            /*that.element.remove();*/

            that.options = null;

        },

        _create: function () {

            var that = this;

        },

        _init: function () {

            var that = this;
            that.options = that.options ?? {}

            // Shortcuts
            if ($.fn.key) {
                that.element.attr('tabindex', '-1').key("ctrl+s", function () {
                    that._request();
                });
            }

            // Status Icon @ Button
            that.element.find("button[type=submit]").each(function () {
                if (!$(this).is("[data-no-icon]") && $(this).find("i").length === 0) {
                    $(this).append(" <i data-state-icon></i>");
                }
            });

            // Hole Einstellungen aus Data Attributen
            that.options = $.extend(true, that.options, that.element.data());

            if (typeof erwingoApp.hashMe != "undefined" && erwingoApp.hashMe.constructor.name === "Function") {

                /**
                 * NOTE:
                 * Has to be updated to work with checkbox + radio as well
                 */

                // Create hash for each input value and save it for _state("edit") check
                that.element.on("hash.form", function () {
                    $(this).find("input:not([type=checkbox]):not([type=radio]),textarea,select").each(function () {
                        var hashVal = typeof $(this).val() != 'undefined' ? $(this).val() : "";
                        $(this).data('fieldHash', erwingoApp.hashMe(hashVal));
                    });
                }).trigger("hash.form");

                // Check changed fields and set status if needed
                that.element.on("keyup change force", "input, textarea, select", function () {

                    var edited = that.element.data("edited") || [],
                        fieldHash = $(this).data('fieldHash'),
                        valHash = erwingoApp.hashMe($(this).val()),
                        name = $(this).attr("name");

                    if (fieldHash == valHash) {
                        if (edited.indexOf(name) > -1) {
                            edited.splice(edited.indexOf(name), 1);
                        }
                    } else {
                        if (edited.indexOf(name) < 0) {
                            edited.push(name);
                        }
                    }

                    // Set status
                    that._state(edited.length ? "edit" : "ok");

                    // Save edited fields
                    that.element.data("edited", edited);

                });

            }

            // Submit für Enter unterbinden, wenn "data-catch-enter" gesetzt)
            if (that.options && typeof that.options.catchEnter != 'undefined') {
                that.element.keypress(function (e) {
                    if (e.which === 13 && !$(e.target).is("textarea")) {
                        e.preventDefault();

                        var nextInput = that.element.find(":input")
                            .filter(":gt(" + that.element.find(":input").index(e.target) + ")")
                            .not('[readonly]')
                            .first();

                        if (nextInput.length > 0) {1953
                            nextInput.focus();
                        }

                    }
                });
            }

            // Submit unterbinden
            that.element.submit(function (e) {
                /*var $button = that.element.find("button[type=submit][clicked=true]");
                if($button.length) {
                    that.options = $.extend(true, that.options, that.element.data()); // Default laden
                    that.options = $.extend(true, that.options, $button.data()); // z.B. Andere fnc ansprechen
                    if (typeof $button.data("confirm") == 'undefined' || confirm($button.data("confirm"))) {
                        that._request();
                    }
                }*/
                return false;
            });

            /*that.element.find("button[type=submit]").click(function() {
                that.element.find("button[type=submit]").removeAttr("clicked");
                $(this).attr("clicked", "true");
            });*/

            // Manuelle Speichern
            that.element.on('click', "button[type=submit]", function () {

                if (that.element.hasClass("disabled")) return false;

                // Check browser's native validation
                var formElem = that.element.get(0);
                if (typeof formElem.reportValidity == 'function' && !formElem.reportValidity()) {
                    return false;
                }

                if (that.options && that.options.subForm) {
                  delete that.options.subForm;
                }

                that.options = $.extend(true, that.options, that.element.data()); // Default laden

                that.options = $.extend(true, that.options, $(this).data()); // z.B. Andere fnc ansprechen

                that.options.clickedButton = $(this);

                if (that.options && that.options.verbose) {
                    console.log("erwingo.form > _init() 'that.options'", that.options);
                }

                if (typeof $(this).data("confirm") != 'undefined') {

                    if (that.options && that.options.verbose) {
                        console.log("erwingo.form > _init() '$(this).data()'", $(this).data());
                    }

                    var confirmTitle = $(this).data().confirmTitle || i18next.t('Warning');
                    var confirmButtonText = $(this).data().confirmButtonText || i18next.t('Confirm');
                    var cancelButtonText = $(this).data().cancelButtonText || i18next.t('Cancel');
                    var $elem = $(this);

                    Swal.fire({

                        title: confirmTitle,
                        html: $(this).data().confirm,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: confirmButtonText,
                        cancelButtonText: cancelButtonText,

                    }).then(function (result) {
                        if (result.isConfirmed) {
                            that._request();
                        }
                    });

                } else {

                    that._request();

                }

            });

            // Auto Speichern
            that.element.on('change', "input[data-autosave],select[data-autosave],textarea[data-autosave]", function () {
                if (that.options && that.options.subForm) {
                  delete that.options.subForm;
                }

                that.options = $.extend(true, that.options, that.element.data()); // Default laden
                that.options = $.extend(true, that.options, $(this).data()); // z.B. Andere fnc ansprechen

                if (that.options) {
                  that.options.clickedButton = $(this);
                }

                that._request();
            });
            that.element.on('blur', "input[data-autosave-blur],textarea[data-autosave-blur]", function () {
                if (that.options && that.options.subForm) {
                  delete that.options.subForm;
                }

                that.options = $.extend(true, that.options, that.element.data()); // Default laden
                that.options = $.extend(true, that.options, $(this).data()); // z.B. Andere fnc ansprechen

                if (that.options) {
                  that.options.clickedButton = $(this);
                }

                that._request();
            });

        },

        _validateForm: function (error) {

            var that = this;

            that.element.find('input[name]:not([type="file"]):not([type="hidden"]),textarea[name],select[name]').each(function () {

                $(this).attr("data-original-title", "");
                $(this).siblings(".error-message").text("");

                $(this).closest(".form-group").removeClass('has-error has-feedback has-success');

                $(this).parent().find('span.form-control-feedback').remove();

                var name = $(this).attr("name");

                if (!name || !name.length) return;

                // var icon = !$(this).is('[type="checkbox"],[type="radio"]');

                if (typeof error === 'object' &&
                    !Array.isArray(error) &&
                    error !== null && error[name]) {

                    $(this).attr("title", error[name])
                        .attr("data-original-title", error[name])
                        .siblings(".error-message").text(error[name]);

                    if ($(this).hasClass("select2-hidden-accessible")) {
                        $(".select2", $(this).closest(".form-group"))
                            .attr("title", error[name])
                            .attr("data-original-title", error[name])
                            .siblings(".error-message").text(error[name]);
                    }

                    if ($(this).parent().hasClass("input-group") && $(this).parent().next(".error-message").length) {
                        $(this).parent().next(".error-message").text(error[name]);
                    }

                    $(this).closest(".form-group").addClass('has-error has-feedback');

                    /* Sollte nur per CSS gestylt werden, keine weitere DOM Elemente // Phil */

                    // $(this).parent().append('<span class="fa fa-times form-control-feedback"></span>');

                } 
                // else {

                    /* Ist meiner Meinung nach unnötig // Phil */

                    // $(this).closest(".form-group").addClass('has-success has-feedback');
                    // $(this).parent().append('<span class="fa fa-check form-control-feedback"></span>');

                // }

            });

        },

        _resetForm: function () {

            var that = this;
            that.options = that.options ?? {}

            if (typeof that.options.subForm == "undefined") {
                $element = that.element;
            } else {
                $element = that.options.clickedButton.closest(that.options.subForm);
            }

            // remove has-success has-feedback, icons and error-message
            that.element.find("input,select,textarea")
                .closest(".form-group")
                .removeClass("has-success has-error has-feedback")
                .find(".form-control-feedback")
                .remove();
            that.element.find(".error-message").text("");

            that.element.find("input.reset").val("").trigger("change");
            that.element.find("textarea.reset").val("").trigger("change");
            that.element.find("select.reset option").prop("selected", false).trigger("change");
            that.element.find("select.reset[data-auto-complete] option").remove();
            that.element.find(".reset").first().focus();

        },

        _request: function () {
            var that = this
            that.options = that.options ?? {}

            // check if this is a batch submit
            if (that.options.sendBatches === true) {
              return that._requestBatches()
            }

            that._state("loading");

            var data = {
                data: that._getInputValues(),
                module: that.options.module,
                cl: that.options.cl,
                fnc: that.options.fnc
            }

            if (that.options.verbose) {
                console.log("erwingo.form > _request() 'data'", data);
            }

            // remove has-success has-feedback, icons and error-message
            that.element.find("input,select,textarea")
                .closest(".form-group")
                .removeClass("has-success has-error has-feedback")
                .find(".form-control-feedback")
                .remove();
            that.element.find(".error-message").text("");

            if (that.options.loaderContainer) {
                that._loaderStart();
            }

            let ajaxOptions = {
                data: data,

                error: function (xhr, error) {
                    that._state("error")
                },

                success: function (data) {
                    that._ajaxSuccessCallback(data)
                },

                onSwalClose: function (result, data) {
                    that._onSwalCloseCallback(result, data)
                }
            }

            if (that.options.blob) {
                let blobOptions = {
                    success: function(data) {
                        that._downloadBlob(data)
                    }
                }

                Object.assign(ajaxOptions, blobOptions)
            }
            erwingoApp.ajax(ajaxOptions)

        },

        _onSwalCloseCallback: function(result, data) {
            var that = this
            that.options = that.options ?? {}

            if (that._getState() != "error" && data.message.icon != 'error' && that.options) {

                // Don't do anything, if onRedirect function already did something (has to return something other than false)
                // This is mainly for dynamictab reload, see listener for form widget in erwingo.loadmodules.js
                if (that.options && (!that.options.onRedirect || !that.options.onRedirect())) {

                    // Only if cl and fnc are same as defined in <form> or button has data-reload itself
                    if (typeof that.options.clickedButton !== "undefined" && (that.options.clickedButton.data('reload')) || (that.options.cl == that.element.data("cl") && that.options.fnc == that.element.data("fnc"))) {

                        if (that.options.redirect == "?" || typeof that.options.reload != 'undefined') {

                            if (that.element.closest(".window").length) {

                                var $window = that.element.closest(".window"), activeTabs = [];
                                $window.find('.tab-content').each(function () {
                                    activeTabs.push($(this).find('> .tab-pane.active').index());
                                });


                                that.element.closest(".window").window('reload', activeTabs);

                            } else {

                                window.location.reload();

                            }

                        } else if (that.options && that.options.redirect) {

                            window.location.href = that.options.redirect;

                        }

                    }

                }

            }
        },

        _ajaxSuccessCallback: function(data) {
            var that = this
            that.options = that.options ?? {}

            if (that.options && that.options.loaderContainer) {
                that._loaderStop();
            }

            if (data == null || typeof data == 'undefined') {
                console.error("[erwingoApp.ajax] Could not load json response. Data is null or undefined.")
                that._state("error")
                return
            }

            // Set Values by Name
            if (typeof data.data != 'undefined') {
                // console.log("Setting Data");
                that._setInputValues(data.data);
            }

            if (that.options && that.options.verbose) {
                console.log("erwingo.form > _request() 'success: data'", data);
            }

            if ((data.message && data.message.icon == "error") || (data.error && ["[]", "{}"].indexOf(JSON.stringify(data.error)) < 0)) {
                that._setErrorMessage(data.message)
                that._state("error")
                that.element.trigger("form.error", [data, that]);

                if (!that.element.prop("novalidate")) {
                    that._validateForm(data.error);
                }
            } else {
                if (typeof that.options.onSuccess === "function") {
                    that.options.onSuccess(that, data);
                    that.options = $.extend(true, that.options, data);
                    that._state("success");
                    that.element.trigger("hash.form");
                    that.element.trigger("form.success", [data, that]);
                    if (typeof data.message == 'undefined') {
                        // Only if cl and fnc are same as defined in <form>
                        if (that.options.cl == that.element.data("cl") && that.options.fnc == that.element.data("fnc")) {
                            // Redirect / Reload
                            if (that.options.redirect == "?" || typeof that.options.reload != 'undefined') {
                                if (that.element.closest(".window").length && !that.options.pageReload) {
                                    that.element.closest(".window").window('reload');
                                } else {
                                    window.location.reload();
                                }
                            } else if (that.options.redirect) {
                                window.location.href = that.options.redirect;
                            }
                        }
                    }
                    // Only if cl and fnc are same as defined in <form>
                    if (that.options && typeof that.options.reset != "undefined" || (that.options.cl == that.element.data("cl") && that.options.fnc == that.element.data("fnc"))) {
                        that._resetForm();
                    }
                }
            }

            that.element.trigger("erwingo.form.success", data);

            // General Ajax Response (Add to Backend-/Frontend.js)
            if (typeof data.trigger != "undefined") {
                $(data.trigger).click();
            }
        },

        // #495: added possibility to download documents inline
        _downloadBlob: function(data) {
            var that = this
            that.options = that.options ?? {}

            if (that.options && that.options.loaderContainer) {
                that._loaderStop();
            }

            if (data == null || typeof data == 'undefined') {
                console.error("[erwingoApp.ajax] Could not load json response. Data is null or undefined.")
                that._state("error")
                return
            }

            data = data.data

            if (!data) {
                return;
            }

            // extract base64 data
            const base64Parts = data.split('\r\n\r\n');
            const base64Head = base64Parts[0];
            const base64Data = base64Parts[1];
            let contentType, filename;

            // try to fetch content type from head information
            const contentTypeRegex = /Content-Type:\s*([^;]+)/i;
            const contentTypeMatch = base64Head.match(contentTypeRegex);
            if (contentTypeMatch && contentTypeMatch[1]) {
                contentType = contentTypeMatch[1].trim();
            } else {
                console.error("[_downloadBlob] Content-Type could not be retrieved!");
                alert("ERROR: File could not be downloaded!");
                return;
            }

            // try to fetch filename from head information
            const filenameRegex = /filename\s*=\s*"([^"]+)"/i;
            const filenameMatch = base64Head.match(filenameRegex);
            if (filenameMatch && filenameMatch[1]) {
                filename = filenameMatch[1].trim();
            } else {
                console.error("[_downloadBlob] Filename could not be retrieved!");
                alert("ERROR: File could not be downloaded!");
                return;
            }

            // decode to binary data and create blob
            let binaryData = atob(base64Data);

            // convert to array buffer
            let arrayBuffer = new ArrayBuffer(binaryData.length);
            let uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryData.length; i++) {
                uint8Array[i] = binaryData.charCodeAt(i);
            }

            // create the blob
            let blob = new Blob([arrayBuffer], { type: contentType });

            // create temp url to download the file
            let url = URL.createObjectURL(blob);

            // download the file
            let dlLink = document.createElement('a');
            dlLink.href = url;
            dlLink.download = filename;
            document.body.appendChild(dlLink);
            dlLink.click();

            // clean up
            document.body.removeChild(dlLink);
            URL.revokeObjectURL(url);

            // reset form state
            that._state("success");
            that.element.trigger("hash.form");
            that.element.trigger("form.success", [data, that]);

            // only if cl and fnc are same as defined in <form>
            if (that.options && typeof that.options.reset != "undefined" || (that.options.cl == that.element.data("cl") && that.options.fnc == that.element.data("fnc"))) {
                that._resetForm();
            }

            that.element.trigger("erwingo.form.success", data);
        },

        // #1405: added possibility to send requests in batches
        _requestBatches: function () {
            var that = this
            that.options = that.options ?? {}

            if (that.options.sendBatches !== true || !that.options.batchKey) {
                return
            }

            const batchKey = that.options.batchKey
            that._state("loading")

            // remove has-success has-feedback, icons and error-message
            that.element.find("input,select,textarea")
                .closest(".form-group")
                .removeClass("has-success has-error has-feedback")
                .find(".form-control-feedback")
                .remove();
            that.element.find(".error-message").text("")

            if (that.options.loaderContainer) {
                that._loaderStart()
            }

            // calculate batches
            that.options.batches = {}
            that.options.batches.batchSize = 5
            that.options.batches.batchValues = []
            that.options.batches.baseValues = []
            const dataValues = that._getInputValues(true)

            dataValues.forEach(field => {
                if (field.name == batchKey) {
                    that.options.batches.batchValues.push(field)
                } else {
                    that.options.batches.baseValues.push(field)
                }
            })

            // prepare counters
            that.options.batches.numBatches = Math.ceil(that.options.batches.batchValues.length / that.options.batches.batchSize)
            that.options.batches.numProcessedBatches = 0
            that.options.batches.numBatchesSent = 0

            // init loading screen progress bar and send requests
            erwingoApp.loadingIndicatorUpdateProgress()
            that._sendBatchRequest()
        },

        // #1405: added possibility to send requests in batches
        _sendBatchRequest: function() {
            var that = this

            let batchData = that.options.batches.batchValues.slice(that.options.batches.numBatchesSent * that.options.batches.batchSize, that.options.batches.numBatchesSent * that.options.batches.batchSize + that.options.batches.batchSize)
            let data = {
                data: that._arrayToSerializedString(that.options.batches.baseValues.concat(batchData)),
                module: that.options.module,
                cl: that.options.cl,
                fnc: that.options.fnc
            }

            if (that.options.verbose) {
                console.log("erwingo.form > _request() 'data'", data);
            }

            // send request
            that.options.batches.numBatchesSent += 1

            erwingoApp.ajax({
                data: data,

                // do not show popup after each call
                // prevent loading indicator from disappearing
                longRunning: true,

                error: function (xhr, error) {
                    // hide loading indicator
                    erwingoApp.hideLoadingIndicator(true)
                    erwingoApp.loadingIndicatorUpdateProgress(-1)
                    that._state("error")
                },

                success: function (data) {
                    if (data == null || typeof data == 'undefined') {
                        console.error("[erwingoApp.ajax] Could not load json response. Data is null or undefined.")
                        // hide loading indicator
                        erwingoApp.hideLoadingIndicator(true)
                        erwingoApp.loadingIndicatorUpdateProgress(-1)
                        that._state("error")
                        return
                    }

                    if (that.options && that.options.verbose) {
                        console.log("erwingo.form > _request() 'success: data'", data);
                    }

                    // abort in case of an error
                    if ((data.message && data.message.icon == "error") || (data.error && ["[]", "{}"].indexOf(JSON.stringify(data.error)) < 0)) {
                        that._setErrorMessage(data.message)
                        that._state("error")
                        that.element.trigger("form.error", [data, that])

                        if (!that.element.prop("novalidate")) {
                            that._validateForm(data.error)
                        }
                    }
                    // update progress on success
                    else {
                        that.options.batches.numProcessedBatches += Math.min(that.options.batches.batchSize, batchData.length)
                        let percentageProcessedBatches = ((that.options.batches.numProcessedBatches / that.options.batches.batchValues.length) * 100).toFixed(2)
                        erwingoApp.loadingIndicatorUpdateProgress(percentageProcessedBatches, percentageProcessedBatches + " %")

                        // if we have batches left to send, continue...
                        if (that.options.batches.numBatchesSent < that.options.batches.numBatches) {
                            that._sendBatchRequest()
                        }
                        // if this was the last batch request, stop execution and show feedback
                        else {
                            // hide loading indicator
                            erwingoApp.hideLoadingIndicator(true)
                            erwingoApp.loadingIndicatorUpdateProgress(-1)

                            // process result and show success state
                            that._ajaxSuccessCallback(data)
                            Swal.fire({
                                title: i18next.t('The data was saved successfully!'),
                                text: i18next.t('There were') + that.options.batches.numProcessedBatches + i18next.t('Entries saved'),
                                icon: "success",
                                timer: 5000,
                                html: i18next.t('There were') + that.options.batches.numProcessedBatches + i18next.t('Entries saved')
                            }).then(function(result) {
                                that._onSwalCloseCallback(result, data)
                            })
                        }
                    }
                }
            })
        },


        _setInputValues: function (data) {

            var that = this;

            data = that._flatten(data, "");

            $.each(data, function (key, value) {

                that.element.find('[name="' + key + '"]').val(value); // What about checkboxes + radio buttons? - Tell me if u need bro

            });

        },

        _state: function (state, exit) {

            var that = this;
            that.options = that.options ?? {}

            // ui-window Widget nur im Backend aktiv
            var hasWidget = that.element.closest(".window").is(':ui-window');

            if (hasWidget) {

                if (state === "edit") {

                    if (that.element.data("warn-me")) {
                        that.element.closest(".window").window("setWarnMe", that.options.warnMe);
                    }

                } else if (["success", "ok"].indexOf(state) > -1) {

                    that.element.closest(".window").window("setWarnMe", null);

                }

            }

            that.options.state = state;

            // error, success, loading

            if (typeof that.options.clickedButton != "undefined" && !that.options.clickedButton.hasClass('hidden')) {
                // Change icon only for clicked button, remove icom from other buttons
                if (typeof that.options.icons !== "undefined") {
                    that.element.find("[data-state-icon]").attr("class", that.options.icons['ok']);
                    that.options.clickedButton.find("[data-state-icon]").attr("class", that.options.icons[state]);
                }
                // If alternative button was clicked, state is still edit, until main form is submitted
                // if (that.options.cl != that.element.data("cl") || that.options.fnc != that.element.data("fnc")) {
                //     if (!exit) {
                //         this._state("edit", true);
                //     }
                // }
            } else {
                if (typeof that.options.icons !== "undefined") {
                    that.element.find("[data-state-icon]").attr("class", that.options.icons[state]);
                }
            }

            // show error message
            if (state === "error") {
              // check if we got an error message from the server
              if (that.errorMessage && that.errorMessage.tstamp) {
                // reset error message object if older than threshold
                const errThreshold = 2 // seconds
                let secondsElapsed = Math.abs(new Date().getTime() - that.errorMessage.tstamp.getTime()) / 1000

                // time elapsed so reset as this is an old error message
                if (secondsElapsed > errThreshold) {
                  that.errorMessage = {}
                }
              }
              // we have no valid error message - reset
              else {
                that.errorMessage = {}
              }

              Swal.fire({
                title: that.errorMessage.title || i18next.t('Error!'),
                icon: 'error',
                text: that.errorMessage.text || i18next.t('Unfortunately, your request could not be processed'),
                timer: Math.max(5000, (that.errorMessage.timer || 0))
              })
            }

            return true
        },

        stateEdit: function () {

            this._state("edit");

        },

        _getState: function () {

            var that = this;

            return that.options ? that.options.state : null;

        },

        /*
         * Format input before retreiving values
         * e.g. change datepicker value from formatted date to datetime
         */
        _formatInputValues: function () {

            var that = this;

            that.element.find(".datepicker").each(function () {
                var moment = $(this).data("DateTimePicker").date();
                if (moment) {
                    $(this).data("unformatted-value", $(this).val());
                    $(this).val(moment.format("YYYY-MM-DD hh:mm:ss"));
                }
            });

        },

        /*
         * Undo changes made by this._formatInputValues()
         */
        _unformatInputValues: function () {

            var that = this;

            that.element.find(".datepicker").each(function () {
                var original = $(this).data("unformatted-value");
                if (original) {
                    $(this).val(original);
                }
            });

        },

        _getInputValues: function (serializeAsArray = false) {

            var that = this
            that.options = that.options ?? {}

            that._formatInputValues()

            let _element = this.element

            if (typeof that.options.subForm != "undefined") {
                _element = that.options.clickedButton.closest(that.options.subForm).find("input,select,textarea")
            }

            let data = serializeAsArray === true ? _element.serializeArray() : _element.serialize()

            that._unformatInputValues()

            return data

        },

        _arrayToSerializedString: function (array) {
            return array.reduce(
                function(accumulator, currentValue) {
                    return accumulator.concat(encodeURIComponent(currentValue.name) + '=' + encodeURIComponent(currentValue.value) + '&')
                },
                ''
            )
        },

        _flatten: function (array, prefix) {

            var that = this;

            var bracket1 = prefix == "" ? "" : "[";
            var bracket2 = prefix == "" ? "" : "]";

            var result = {};

            $.each(array, function (key, value) {

                // console.log(typeof value);

                if (typeof value == 'object') {

                    $.extend(result, that._flatten(value, prefix + bracket1 + key + bracket2));

                } else {

                    result[prefix + bracket1 + key + bracket2] = value;

                }

            });

            return result;

        },

        _buildLoader: function () {

            var that = this;
            that.options = that.options ?? {}

            var $wrap = $('<div class="erwingo-form-loader-container" style="display:none;"></div>'),
                $loader = $('<div class="erwingo-form-loader"></div>'),
                $spinner = $('<i class="fa fa-refresh fa-spin erwingo-form-loader-spinner"></i>'),
                $info = that.options.loaderText ? $('<span></span>').append(that.options.loaderText) : "";

            return $wrap.append($loader.append($spinner, $info));

        },

        _loaderStart: function () {

            var that = this;
            that.options = that.options ?? {}

            var $container = that.element.closest(that.options.loaderContainer).add(that.element.find(that.options.loaderContainer)).first();

            if ($container.length) {
                var $loader = that._buildLoader();
                $container.addClass("has-erwingo-form-loader").append($loader);
                $loader.fadeIn(200);
            }
        },

        _loaderStop: function () {

            var that = this;
            that.options = that.options ?? {}

            var $container = that.element.closest(that.options.loaderContainer).add(that.element.find(that.options.loaderContainer)).first();

            var $loader = $container.removeClass("has-erwingo-form-loader").find(".erwingo-form-loader-container");
            $loader.fadeOut(200, function () {
                $(this).remove();
            });


        },

        _setErrorMessage: function(errorMsg = {}) {
          if (errorMsg) {
            this.errorMessage = errorMsg
            this.errorMessage["tstamp"] = new Date()
          }
        }

    });

})(jQuery);
