// import 'chart.js' // probably not required
global.erwingoApp = window.erwingoApp = {};

// global constant values
erwingoApp.EMPTYDATE = '0000-00-00';
erwingoApp.EMPTYDATETIME = '0000-00-00 00:00:00';


erwingoApp.options = {
    'url': url_api,
    'debug': 1,
    'requestCount': 0,
    'ajaxInitiator': typeof (ajaxInitiator) != 'undefined' ? ajaxInitiator : "frontend"
};
erwingoApp.loadingDialog = {
    start: function () {
        $('#loading').modal('show');

        $('#loadingClose').hide();
        $("#loadingConsole").empty();
        $("#progressStatusLabel").text(i18next.t('Uploading data...'));
    },
    progress: function (width, text) {
        $('#progress .progress-bar')
            .css('width', width + '%')
            .text(text);
        $("#progressStatusLabel").text(i18next.t('Data is being processed, which may take some time.\\n\\n Please wait...'));
    },
    console: function (append, nl) {
        if (typeof nl == 'undefined') {
            var nl = "\n";
        }
        $("#loadingConsole").append(append + nl);
        if ($("#loadingAutoScroll").prop("checked")) {
            var psconsole = $('#loadingConsole');
            psconsole.scrollTop(psconsole[0].scrollHeight - psconsole.height());
        }
    },
    stop: function () {
        $("#progressStatusLabel").text(i18next.t('Data has been uploaded and processed.'));
        $('#loadingClose').show();
            setTimeout(function () {
                $('#loading').modal('hide');
            }, 500);
    }
};
erwingoApp.ajax = function (options, verbose) {
    // show loading indicator whenever a request is sent to the server
    // unless we hide it on purpose
    if (!options.ignoreLoadingIndicator == true) {
      erwingoApp.showLoadingIndicator()
    }

    var verbose = verbose || false;
    // console.log("JSON Request", options);
    options.data.initiator = erwingoApp.options.ajaxInitiator;
    var defaults = {
        url: options.url || url_api,
        type: "POST",
        data: options.data,
        success: function (json) {
            var data = null;
            try {
                data = JSON.parse(json);
            } catch (err) {
                let req = options.data;
                function objToString (obj) {
                    return Object.entries(obj).reduce((str, [p, val]) => {
                        return `${str}${p}::${val}\n`;
                    }, '');
                }
                if (typeof req === 'object') {
                    req = objToString(req);
                }
                let report = {"request": req, "jsError" : err.message, "codeError" : JSON.stringify(json)};
                console.log(report);
                Sentry.captureException(report);
            }

            if (options.longRunning !== true) {
              erwingoApp.processJsonResult(data, options);
            }

            if (typeof options.success !== "undefined" && options.success !== undefined && options.success !== null) {
                options.success(data);
            }
        },
        complete: function(result, status) {
          // hide loading indicator when response comes from server
          // unless we have a long-running operation
          if (options.longRunning !== true) {
            erwingoApp.hideLoadingIndicator()
          }

          if (typeof options.complete !== "undefined" && options.complete !== undefined && options.complete !== null) {
            options.complete(result, status);
          }
        }
    };
    // console.log(defaults);
    return $.ajax(defaults);
};
erwingoApp.calcHeight = function () {
    $(document).find(".calcHeight").each(function () {
        var maxHeight = $(this).height();
        $(this).children(".fixedHeight").each(function () {
            maxHeight = maxHeight - $(this).outerHeight(true);
        });
        $(this).children(".autoHeight").outerHeight(maxHeight);
    });
};
erwingoApp.destroyModules = function (target) {
    $(target).find('select:not([data-auto-complete]):not(.plain), select[data-auto-complete]').select2('destroy');
};
// Reload page if user got logged out
erwingoApp.checkUser = function (userType, timeout) {
    erwingoApp.ajax({
        data: {cl: "AccessControl", fnc: "checkLoginStatus", userType: userType},
        ignoreLoadingIndicator: true,
        success: function (response) {
            if (typeof user != "undefined" && response.active != user) {
                erwingoApp.autologout();
            } else {
                user = response.active;
            }
        }
    });
    return setTimeout(function () {
        erwingoApp.checkUser(userType, timeout);
    }, timeout);
};
erwingoApp.genText = function (html, options) {
    if (typeof html != 'undefined') {
        html = html.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
        $.each(options, function (key, value) {
            var regExpKey = new RegExp("\\@" + key + "\\@", "gi");
            html = html.replace(regExpKey, value);
        });
        $.each(options, function (key, value) {
            var regExpKey = new RegExp("\\{[\\$]*" + key + "\\}", "gi");
            html = html.replace(regExpKey, value);
        });
        var re = /<%([^%>]+)?%>/g, reExp = /(^( )?(if|for|else|switch|case|break|{|}))(.*)?/g, code = 'var r=[];\n',
            cursor = 0;
        var add = function (line, js) {
            js ? (code += line.match(reExp) ? line + '\n' : 'r.push(' + line + ');\n') :
                (code += line != '' ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n' : '');
            return add;
        }
        while (match = re.exec(html)) {
            add(html.slice(cursor, match.index))(match[1], true);
            cursor = match.index + match[0].length;
        }
        add(html.substr(cursor, html.length - cursor));
        code += 'return r.join("");';
        return new Function(code.replace(/[\r\t\n]/g, '')).apply(options);
        return html;
    } else {
        return '...';
    }
};
erwingoApp.hashMe = function (obj) {
    if (obj == null || obj == undefined) {
        obj = "";
    }
    try {
        var hashable = obj;
        if (hashable.constructor.name != "String") {
            hashable = JSON.stringify(hashable);
        }
        var hash = 0, i, chr;
        if (hashable.length === 0) return hash;
        for (i = 0; i < hashable.length; i++) {
            chr = hashable.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
    } catch (err) {
        console.log(obj, err);
        var hash = erwingoApp.hashMe("");
    }
    return hash;
};
erwingoApp.translate = function (args) {
    if (typeof args == "string") {
        var key = args;
    } else if (args.key) {
        var key = args.key;
    }
    if (_LANG_[key]) {
        return _LANG_[key];
    } else {
        return "[No translation: '" + key + "']";
    }
};
erwingoApp.updateChart = function (chartElement) {
    var options = {
        module: '',
        cl: '',
        fnc: '',
        chart: '',
        search: '',
        config: {},
        filters: {}
    };
    var options = $.extend(true, options, chartElement.data());
    var searchElement = $('#' + options.search + 'search');
    var chartId = chartElement.attr('id');
    //convert jQuery object into a string
    var chartElementOrigin = chartElement.prop('outerHTML');
    var parent = chartElement.parent();
    var update = function () {
        erwingoApp.ajax({
            data: options,
            success: function (response) {
                $(document.getElementById(chartId)).replaceWith(chartElementOrigin);
                if (!$.isEmptyObject(response)) {
                    parent.find('#noResults').hide();
                    if (options.chart == 'plotly') {
                        var ctx = document.getElementById(chartId);
                        var layout = $.extend(response.layout, {
                            height: parent.hasClass('widget-body') ? parent.parent().height() : parent.height(),
                            width: parent.hasClass('widget-body') ? parent.parent().width() : parent.width()
                        });
                        Plotly.plot(ctx, response.plotly, layout, {
                            modeBarButtonsToRemove: ['sendDataToCloud'],
                            displaylogo: false
                        });
                        /*

                        //Bad solution!! Only works with Popups

                        parent.parent().parent().parent().resize(function() {
                            Plotly.relayout(ctx, {
                                width:parent.width(),
                                height:parent.height()
                            })
                        });

                        */
                        /*
                        //This solution is better, but only works with popups
                        var observer = new MutationObserver(function(mutations) {
                            mutations.forEach(function(mutationRecord) {
                                Plotly.relayout(ctx, {
                                    height:parent.hasClass('widget-body') ? parent.parent().height() : parent.height(),
                                    width:parent.hasClass('widget-body') ? parent.parent().width() : parent.width()
                                });
                            });
                        });

                        //parent.[0] -> native DOM element from a jQuery
                        var target = parent[0];
                        observer.observe(target, {attributes: true, attributeFilter: ['style']});
                        */
                        var disableObserver = false;
                        var observer = new MutationObserver(function (mutations) {
                            if (!disableObserver) {
                                if (mutations.length < 20) {
                                    mutations.forEach(function (mutation) {
                                        /*
                                        if (mutation.addedNodes && mutation.addedNodes.length > 0) {
                                            // element added to DOM
                                            if(mutation.addedNodes[0].id == 'gridster-stylesheet-#widgets'){

                                            }
                                        }
                                        */
                                        //console.log(mutation);
                                        if (mutation.addedNodes.length > 0) {
                                            setTimeout(function () {
                                                Plotly.relayout(ctx, {
                                                    height: parent.hasClass('widget-body') ? parent.parent().height() : parent.height(),
                                                    width: parent.hasClass('widget-body') ? parent.parent().width() : parent.width()
                                                });
                                            }, 200);
                                        } else {
                                            Plotly.relayout(ctx, {
                                                height: parent.hasClass('widget-body') ? parent.parent().height() : parent.height(),
                                                width: parent.hasClass('widget-body') ? parent.parent().width() : parent.width()
                                            });
                                        }
                                    });
                                }
                            }
                        });
                        var config = {
                            attributes: true,
                            childList: true,
                            characterData: true,
                            attributeFilter: ['style']
                        };
                        var target = parent.hasClass('widget-body') ? document.head : parent[0];
                        observer.observe(target, config);
                    } else if (options.chart === 'chart') {
                        var ctx = document.getElementById(chartId);
                        // var chart = new Chart (ctx,[response]);
                        var chart = new Chart(ctx, {
                            type: response.type,
                            data: response.chart,
                            options: response.options
                        });
                    }
                } else {
                    parent.find('#noResults').show();
                }
            }
        });
    };
    update();
    searchElement.find('[name]').on("change dp.change", function () {
        searchElement.closest('.widget').find('iframe').remove();
        searchElement.find('[name]').each(function () {
            options.filters[$(this).attr("name")] = $(this).val();
        });
        update();
    });
};
erwingoApp.contextMenu = function (options) {
    var contextMenuData = {};
    var contextMenuOptions = {
        selector: options.selector,
        build: function ($trigger, e) {
            // this callback is executed every time the menu is to be shown
            // its results are destroyed every time the menu is hidden
            // e is the original contextmenu event, containing e.pageX and e.pageY (amongst other data)
            return {
                zIndex: 999,
                //callback: function (key, opt) {},
                items: items,
                events: {
                    show: function (opt) {
                        // this is the trigger element
                        var $this = this;
                        // import states from data store
                        if (!$.isEmptyObject(closedWidgets) && $.isEmptyObject($this.data())) {
                            contextMenuData = $.contextMenu.getInputValues(opt, $this.data());
                            mergeContextData();
                        }
                        if (!$.isEmptyObject($this.data())) {
                            mergeContextData();
                            $.contextMenu.setInputValues(opt, $this.data());
                            // this basically fills the input commands from an object
                            // like {name: "foo", yesno: true, radio: "3", &hellip;}
                        }
                    },
                    hide: function (opt) {
                        // this is the trigger element
                        var $this = this;
                        // export states to data store
                        contextMenuData = $.contextMenu.getInputValues(opt, $this.data());
                        // this basically dumps the input commands' values to an object
                        // like {name: "foo", yesno: true, radio: "3", &hellip;}
                    },
                }
            };
        }
    };

    function mergeContextData() {
        contextMenuData = $.extend(contextMenuData, closedWidgets);
    }

    var items = {};
    erwingoApp.ajax({
        data: {
            module: "erwingo",
            cl: "FormAdmin",
            fnc: "getContextData",
        },
        success: function (response) {
            processItems(response);
            $.contextMenu(contextMenuOptions);
        }
    });

    function processItems(param) {
        param.forEach(function (key) {
            var label = Object.keys(key)[0];
            if (label == 'widgets') {
                key = key[label];
                label = Object.keys(key)[0];
                for (k in key) {
                    for (item in key[k]['items']) {
                        if (item == "save") {
                            key[k]['items'][item]['callback'] = function (itemKey, opt) {
                                var data = [];
                                var counter = 0;
                                var col = 1;
                                var row = 1;
                                var openWidgets = [];
                                var closeWidgets = [];
                                $.each(opt.inputs, function (key, item) {
                                    if (item.type == "checkbox") {
                                        var res = item.$input.val().split(':');
                                        if (item.$input.prop('checked')) {
                                            var x = parseInt(res[2]);
                                            var y = parseInt(res[3]);
                                            openWidgets.push(res[0] + ':' + res[1]);
                                            counter += x;
                                            if (counter <= 6) {
                                                data.push(res[0] + ':' + res[1] + ':' + col + ':' + row + ':' + x + ':' + y)
                                            } else {
                                                row += 1;
                                                counter = x;
                                                col = 1;
                                                data.push(res[0] + ':' + res[1] + ':' + col + ':' + row + ':' + x + ':' + y)
                                            }
                                            col += x;
                                        } else {
                                            closeWidgets.push(res[0] + ':' + res[1]);
                                        }
                                    }
                                });
                                erwingoApp.ajax({
                                    data: {
                                        module: "erwingo",
                                        cl: "FormAdmin",
                                        fnc: "updateWidgetsContextMenu",
                                        widgetData: data,
                                    },
                                    success: function (response) {
                                        //window.location.reload();
                                        closeWidgets.forEach(function (widget) {
                                            var thisWidget = $('#widget-select-list').find('[name="' + widget + '"]');
                                            if (thisWidget.is(':checked')) {
                                                thisWidget.prop('checked', false).trigger('change');
                                            }
                                        });
                                        openWidgets.forEach(function (widget) {
                                            var thisWidget = $('#widget-select-list').find('[name="' + widget + '"]');
                                            if (!thisWidget.is(':checked')) {
                                                thisWidget.prop('checked', true).trigger('change');
                                            }
                                        });
                                        erwingoApp.ajax({
                                            data: {
                                                module: "erwingo",
                                                cl: "Backend",
                                                fnc: "getWidgets",
                                            },
                                            success: function (response) {
                                                var gridster = $(document).find("#widgets > ul");
                                                gridster.removeData('drag');
                                                gridster.data('gridster').destroy();
                                                gridster.empty().append(response);
                                                gridster.gridster(options.gridsterOption).data('gridster');
                                                erwingoApp.loadModules("#widgets");
                                            }
                                        });
                                        closedWidgets = {};
                                    }
                                });
                            }
                        }
                    }
                }
            }
            if (typeof key[label]['ajax'] !== 'undefined') {
                key[label]['callback'] = function () {
                    erwingoApp.ajax({
                        data: key[label]['ajax'],
                        success: function (response) {
                        }
                    });
                }
            }
            if (typeof key[[label]]['popup'] !== 'undefined') {
                key[label]['callback'] = function () {
                    $("<div></div>").window(key[label]['popup']);
                }
            }
            items = $.extend(items, key);
        });
        //console.log(items);
    }

    var state = true;
    $(document).keydown(function (e) {
        //enable or disable menu on keydown esc
        if (e.which == 27) {
            state = !state;
            if (!state) {
                $.contextMenu('destroy');
            } else {
                $.contextMenu(contextMenuOptions);
            }
        }
    });
};
erwingoApp.dynamicPopover = function ($elem) {
    if ($elem.data("popoverContent")) {
        return $elem.data("popoverContent");
    } else {
        // Prevent double call (bootstrap bug)
        if (!$elem.data("contentCalled")) {
            $elem.data("contentCalled", true);
            return " ";
        }
        var data = $elem.data(),
            id = "tmp-id-" + $.now();
        var reqData = {
            module: data.popoverModule || "",
            cl: data.popoverCl,
            fnc: data.popoverFnc,
        };
        // Add other data attributes
        $.each($elem.data(), function (k, v) {
            if (["cl", "fnc", "module", "popoverModule", "popoverCl", "popoverFnc", "popoverContent", "contentCalled"].indexOf(k) < 0 && typeof v == "string") {
                reqData[k] = v;
            }
        });
        erwingoApp.ajax({
            data: reqData,
            success: function (response) {
                $("#" + id).html(response);
                $elem.data("popoverContent", response);
                $elem.data("contentCalled", false);
                $elem.popover("reposition");
            }
        });
        return '<div id="' + id + '"><i class="fa fa-spinner fa-spin fa-2x fa-fw text-muted"</div>';
    }
};
erwingoApp.autologout = function () {
    var url = window.location.href;
    if (url.indexOf('autologout') == -1) {
        var qs = (url.indexOf('?')) > -1 ? '&autologout' : '?autologout';
        if (url.indexOf('#') > -1) {
            window.location.href = url.replace("#", qs + "#");
        } else {
            window.location.href = url + qs;
        }
    } else {
        window.location.reload();
    }
};


erwingoApp.showLoadingIndicator = function () {
  // increase counter as we got a new request
  erwingoApp.options.requestCount += 1

  $(".preloader").removeClass("d-none")
}


erwingoApp.hideLoadingIndicator = function (force = false) {
  // reduce counter as a request was finished
  erwingoApp.options.requestCount -= 1

  // only hide loading indicator if no more requests are running
  if (erwingoApp.options.requestCount <= 0 || force === true) {
    erwingoApp.options.requestCount = 0
    $(".preloader").addClass("d-none")
  }
}


erwingoApp.loadingIndicatorUpdateProgress = function(width = 0, text = '0 %') {
    if (width === null || width === undefined || width === '' || width < 0) {
      $('.preloader .progress').addClass("d-none")
      $('.preloader .spinner').css('height', '160px')
    } else {
      $('.preloader .spinner').css('height', '200px')
      $('.preloader .progress').removeClass("d-none")

      $('.preloader .progress .progress-bar')
        .css('width', Math.min(100, width) + '%')
        .text(text)
    }
}


erwingoApp.getAffectedCheckboxes = function(queryId) {
    const domQuery = `#${queryId}ScrollTable input:not([disabled]):checkbox`
    const availableForCheck = $(domQuery).length
    const participants = erwingoApp.getDisabledCheckboxesCount(queryId)
    const checked = $(domQuery + ':checked').length

    if (availableForCheck === checked && availableForCheck !== 0) {
        $(`input[name="${queryId}checkall"]`).prop('checked', true)
    }
    else {
        $(`input[name="${queryId}checkall"]`).prop('checked', false)
    }

    $(`#${queryId}newAffected`).text(checked)
    $(`#${queryId}totalAffected`).text(participants + checked)
}


erwingoApp.getDisabledCheckboxesCount = function(queryId) {
    return $(`#${queryId}ScrollTable input:checkbox:disabled`).length
}

$(document).on("click",".change-language",function() {
    localStorage.setItem('locale', this.id);
});
