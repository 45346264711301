/*

 * Description: jQuery-Plugin um dynamische Inhalte in einen Container zu laden
 */

(function ($) {

    $.fn.dynamictab = function (options) {

        this.each(function (index) {

            var that = this;

            this.dynamictab = {};
            this.dynamictab.settings = $.extend({

                module: 'erwingo',
                cl: '',
                fnc: '',
                url: 'ajax.php',
                backdropContainer: false,

                onSuccess: function () {
                },
                onError: function () {
                    console.log("dynamictab request() fail", arguments);
                },

            }, options);

            this.dynamictab.settings.target = $(this).data("dt-target") || this.dynamictab.settings.target;
            this.dynamictab.settings.module = $(this).data("dt-module") || this.dynamictab.settings.module;
            this.dynamictab.settings.cl = $(this).data("dt-cl") || this.dynamictab.settings.cl;
            this.dynamictab.settings.fnc = $(this).data("dt-fnc") || this.dynamictab.settings.fnc;
            this.dynamictab.settings.backdropContainer = $(this).data("dt-backdrop-container") || this.dynamictab.settings.backdropContainer;

            if (!$(this.dynamictab.settings.target).length) {
                return console.log("dynamictab target '" + this.dynamictab.settings.target + "' not found.");
            }

            $(this.dynamictab.settings.target).addClass("dynamictab-container");

            $(this.dynamictab.settings.target).dynamictab.first_trigger = $(this);

            switch ($(this).prop("tagName").toLowerCase()) {

                case "select":
                case "input":
                case "textarea":
                    $(this).on('change', function (e) {
                        $(this).data('value', $(this).val());
                        request.call(this, e);
                    });
                    break;

                default:
                    $(this).on('click', request);
                    break;

            }

            /* Methods */

            function request(e) {

                e.preventDefault();

                var data = {};
                $.each($(this).data(), function (k, v) {
                    if (['number', 'string'].indexOf(typeof v) > -1) {
                        data[k] = v;
                    }
                });

                var req = {
                    data: data,
                    module: this.dynamictab.settings.module,
                    cl: this.dynamictab.settings.cl,
                    fnc: this.dynamictab.settings.fnc
                };

                // console.log("dynamictab request", this.dynamictab.settings.url, req);

                $.post(this.dynamictab.settings.url, req, function (response) {

                    // console.log("dynamictab response", response);

                    /* updateUrlQueryParam("dt-preload", index); */

                    $target = $(that.dynamictab.settings.target);

                    if (typeof response.ident != 'undefined') {
                        // Toggle functionality
                        if ($target.data('dynamictab-id') == response.ident) {
                            $target.text("").css({display: ""}).data('dynamictab-id', "");
                        } else {
                            $target.html(response.content).show().data('dynamictab-id', response.ident);
                        }
                    } else {
                        $target.html(response.content).show();
                    }

                    var $modal = $(that.dynamictab.settings.target).closest(".modal");
                    if ($modal.length) {
                        $modal.modal('show');

                        if (that.dynamictab.settings.backdropContainer) {
                            $('.modal-backdrop').appendTo($(that.dynamictab.settings.backdropContainer)).css("position", "absolute");
                            $('body').removeClass("modal-open").css("padding-right", "");
                        }

                        if ($modal.is("[data-contained]")) {
                            $('.modal-backdrop').appendTo($modal.parent()).css("position", "absolute");
                            $('body').removeClass("modal-open").css("padding-right", "");
                        }
                    }

                    var $fw = $(that.dynamictab.settings.target).closest(".formwatcher");
                    if ($fw.length) {
                        $fw.formwatcher("reinit");
                    }

                    that.dynamictab.settings.onSuccess();

                }, 'json').fail(this.dynamictab.settings.onError);

                return false;

            }

        });

    }

    function updateUrlQueryParam(key, value) {
        var uri = window.location.href;
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";


        if (uri.match(re)) {
            uri = uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            uri = uri + separator + key + "=" + value;
        }

        var stateObj = {};
        stateObj[key] = value;
        console.log("pushState", uri);
        history.pushState(stateObj, "", uri);
    }

}(jQuery));