// import ClassicEditor from '../components/ckeditor';
erwingoApp.loadModules = function (target) {
    // Disabled forms of soft deleted entities
    if ($(target).find('.soft-deleted-entity-warning').length) {
        $(target).find('form :input,button').prop('disabled', true);
    }
    if (typeof $.fn.jstree == 'function') {
        $(target).find("[data-tree]").jstree({
            "core": {
                "animation": 0,
                "check_callback": true,
                "themes": {"stripes": true},
                'data': {
                    'url': function (node) {
                        return node.id === '#' ?
                            'ajax_demo_roots.json' : 'ajax_demo_children.json';
                    },
                    'data': function (node) {
                        return {'id': node.id};
                    }
                }
            },
            "types": {
                "#": {
                    "max_children": 1,
                    "max_depth": 4,
                    "valid_children": ["root"]
                },
                "root": {
                    "icon": "/static/3.3.5/assets/images/tree_icon.png",
                    "valid_children": ["default"]
                },
                "default": {
                    "valid_children": ["default", "file"]
                },
                "file": {
                    "icon": "glyphicon glyphicon-file",
                    "valid_children": []
                }
            },
            "plugins": [
                "contextmenu", "dnd", "search",
                "state", "types", "wholerow"
            ]
        });
    }
    if (typeof CodeMirror == 'function') {
        $(target).find("[data-code]").each(function () {
            let mode = $(this).data("code");
            let cm = CodeMirror.fromTextArea(this, {
                lineNumbers: true,
                mode: mode,
                gutters: ["CodeMirror-lint-markers"],
                lint: true
            });
            cm.on('change', function () {
                cm.save();
            });
            setTimeout(function () {
                cm.refresh();
            }, 1000);
        });
        $('a[data-bs-toggle="tab"]').on('shown.bs.tab', function () {
            let content = $(this).attr('href')
            if (content) {
                content = $(content)
                if (content.find('.CodeMirror').length) {
                    content.find('.CodeMirror')[0].CodeMirror.refresh();
                }
            }
        });
    }
    if (typeof $.fn.apiListing == 'function') {
        $(target).find('[data-api-listing]').apiListing({
            url: erwingoApp.options.url,
            controller: 'Listing',
            formController: 'Form',
            module: $(this).data("module") || "",
            // verbose: true // umfassendes logging aktivieren
            onUpdate: function (elem) {
                erwingoApp.loadModules(elem.find(".body"));
            }
        });
    }
    if (typeof $.fn.apiForm == 'function') {
        $(target).find('form[data-form]').apiForm({
            onSuccess: function (obj, data) {
                // Update all lists
                if (!data.redirect) {
                    if (typeof $.fn.apiListing == 'function') {
                        $("[data-api-listing]").each(function () {
                            $(this).apiListing('update');
                            let checker1 = $(".listactions-checkall");
                            let checker2 = $(".listactions-checkall2");
                            // records on this page
                            if (checker1.length > 0) {
                                checker1.prop("indeterminate", false);
                                checker1.prop("checked", false);
                            }
                            // all records
                            if (checker2.length > 0) {
                                checker2.prop("indeterminate", false);
                                checker2.prop("checked", false);
                            }
                        });
                    }
                }
                if (data.close_modal && obj.element.closest('.modal').length) {
                    obj.element.closest('.modal').modal('hide');
                }
            },
            onRedirect: function () {
                if (typeof $.fn.dynamictab == 'function') {
                    // If this form is inside dynamictab or dynamictabs are inside this fOrm, reload dynamictab(s)
                    var $dyntabParents = $(this.uiApiForm.element).closest(".dynamictab-container");
                    $dyntabParents = $dyntabParents.add($(this.uiApiForm.element).find(".dynamictab-container"));
                    if ($dyntabParents.length) {
                        var output = false;
                        $dyntabParents.each(function () {
                            if (typeof $(this).dynamictab.first_trigger != 'undefined') {
                                $(this).dynamictab.first_trigger.trigger("change");
                                output = !!$(this).dynamictab.first_trigger.length;
                            }
                        });
                        // Return true if dyntab trigger was found, so window/page doesn't reload
                        return output;
                    }
                }
            }
        });
    }
    $(target).find('select[data-value]:not([data-auto-complete])').each(function () {
        var $select = $(this);
        if (typeof $select.data("value") != "undefined" && $select.data("value").length > 0) {
            var values = $select.data("value").split(",");
            $.each(values, function (k, v) {
                $select.find('option[value="' + v + '"]').prop("selected", true);
            });
        }
    });
    if (typeof $.fn.select2 == 'function') {
        $(target).find('select:not([data-auto-complete]):not(.plain)').each(function () {
            var options = {
                placeholder: $(this).attr('placeholder') || "",
                allowClear: true
            };
            if ($(this).next().hasClass("select2-parent")) {
                options.dropdownParent = $(this).next();
            }
            if ($(this).data("allowClear") == 0) {
                options.allowClear = false;
            }
            // Add hidden input to make sure empty values is sent if nothing is selected
            let name = $(this).attr('name')
            if (name) {
                $('<input type="hidden" class="select2-custom-no-option-selected" value="">')
                    .attr('name', name.replace('[]', ''))
                    .insertBefore($(this));
            }
            $(this).select2(options);
            if ($(this).data("select2").$container) {
                var $container = $(this).data("select2").$container;
                $container.addClass($(this).attr("class"))
                    .removeClass("select2-hidden-accessible")
                    .css({width: ''})
                    .find(".select2-selection")
                    .addClass($(this).attr("class"))
                    .removeClass("select2-hidden-accessible");
            }
        });
    }
    $(target).find('select[data-auto-complete]').each(function () {
        // Load options of data-value is set
        var value = $(this).data('value');
        var first = $(this).data('select-first');
        var $elem = $(this);
        var extra = {};
        $.each($elem.data(), function (k, v) {
            if (["autoComplete", "cl", "fnc", "module"].indexOf(k) < 0 && typeof v == "string") {
                extra[k] = v;
            }
        });
        $elem.data("extra", extra);
        if (typeof first != 'undefined') {
            value = "__first__";
        }
        if (typeof value != 'undefined' && value) {
            var req = {
                controller: 'Autocomplete',
                action: $elem.data("autoComplete") || "",
                value: value,
                cl: $elem.data("cl") || "",
                fnc: $elem.data("fnc") || "",
                module: $elem.data("module") || "",
                extra: $elem.data("extra"),
                initiator: typeof ajaxInitiator != "undefined" ? ajaxInitiator : "",
            }
            $.post(erwingoApp.options.url, req, function (response) {
                // console.log(req, response);
                if (response && response.length) {
                    $elem.text("");
                    $.each(response, function (k, v) {
                        $elem.append('<option value="' + v.value + '" selected="selected">' + v.label + '</option>');
                    });
                    var hashVal = typeof $elem.val() != 'undefined' ? $elem.val() : "";
                    $elem.data('fieldHash', erwingoApp.hashMe(hashVal));
                    $elem.trigger("change");
                }
            }, 'json').fail(function () {
                console.log("get select value fail", arguments);
            });
        }
        var options = {
            placeholder: $(this).attr('placeholder') || "",
            allowClear: true,
            ajax: {
                url: erwingoApp.options.url,
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    var $elem = $(this);
                    var action = $elem.data("autoComplete") || "";
                    var cl = $elem.data("cl") || "";
                    var fnc = $elem.data("fnc") || "";
                    var module = $elem.data("module") || "";
                    var extra = $elem.data("extra");
                    if ($elem.data("dependency-input")) {
                        var $dependencyInput = $($elem.data("dependency-input"));
                        extra.dependency = $dependencyInput.val();
                        if ($dependencyInput.data("entity")) {
                            extra.dependencyDropdown = $dependencyInput.data("entity");
                        }
                    }
                    var reqData = {
                        controller: 'Autocomplete',
                        action: action,
                        data: params.term,
                        cl: cl,
                        fnc: fnc,
                        module: module,
                        extra: extra,
                        initiator: typeof ajaxInitiator != "undefined" ? ajaxInitiator : "",
                    };
                    // console.log(params, reqData);
                    return reqData;
                },
                processResults: function (data, params) {
                    // console.log(data, params);
                    // $elem.closest("form[data-form]").apiForm("stateEdit");
                    var results = [];
                    $.each(data, function (k, v) {
                        if (v.children) {
                            results[k] = {};
                            results[k].text = v.label;
                            results[k].children = [];
                            $.each(v.children, function (k1, v1) {
                                results[k].children.push({
                                    id: v1.value,
                                    text: v1.label
                                });
                            });
                        } else {
                            // we just rename keys "value" and "label" and keep all other key-pairs as well to allow additional data
                            var opt = v;
                            opt['id'] = opt['value'];
                            opt['text'] = opt['label'];
                            delete opt['value'];
                            delete opt['label'];
                            results.push(opt);
                        }
                    });
                    return {
                        results: results
                    };
                },
                //cache: true
            },
            escapeMarkup: function (markup) {
                return markup;
            }, // let our custom formatter work
            minimumInputLength: 0,
        };
        /* Insert data using select2 */
        var insert = typeof $(this).data("insert") != "undefined";
        var insertCl = $(this).data("insert-cl") || false;
        var insertFnc = $(this).data("insert-fnc") || false;
        var insertCol = $(this).data("data-insert-column") || false;
        if (insert) {
            options['tags'] = true;
            options['createTag'] = function (params) {
                var term = $.trim(params.term);
                if (term === '') {
                    return null;
                }
                return {
                    id: term,
                    text: term,
                    newTag: true
                }
            }
            options['insertTag'] = function (data, tag) {
                tag.text += ' <small class="new-entry">+ ' + i18next.t('Please select for new entry') + '</small>';
                data.unshift(tag);
            }
        }
        if (insertCl && insertFnc && insertCol) {
            options['tags'] = true;
            options['createTag'] = function (params) {
                // console.log("createTag", insertCl, insertFnc, insertCol, params);
            }
        }
        /* -/- */
        if (typeof $.fn.select2 == 'function') {
            if ($(this).next().hasClass("select2-parent")) {
                options.dropdownParent = $(this).next();
            }
            // Add hidden input to make sure empty values is sent if nothing is selected
            let name = $(this).attr('name')
            if (name) {
                $('<input type="hidden" class="select2-custom-no-option-selected" value="">')
                    .attr('name', name.replace('[]', ''))
                    .insertBefore($(this));
            }
            var select2 = $(this).select2(options).data("select2");
            if (select2.$container) {
                var $container = select2.$container;
                $container.addClass($(this).attr("class"))
                    .removeClass("select2-hidden-accessible")
                    .css({width: ''})
                    .find(".select2-selection")
                    .addClass($(this).attr("class"))
                    .removeClass("select2-hidden-accessible");
            }
            // hacky workaround to fix autofocus for all select2 dropdown fields
            // since latest versions of jquery, select 2 is broken.
            // see: https://github.com/select2/select2/issues/5993
            $(document).on('select2:open', () => {
                let allFound = document.querySelectorAll('.select2-container--open .select2-search__field');
                allFound[allFound.length - 1].focus();
            });
        }
    });
    // CSV Parser für große Imports
    if (typeof $.fn.parse == 'function') {
        $(target).find('[data-parse]').change(function () {
            var type = $(this).data('parse');
            $(this).parse({
                config: {
                    header: true,
                    complete: function (results, file) {
                        // console.log("Parsing complete:", results, file);
                        var dataCount = results.data.length;
                        var exportStep = function (data, type, dataCount) {
                            var row = data.shift();
                            var loaded = (dataCount - data.length);
                            var progress = parseInt(loaded / dataCount * 100, 10);
                            erwingoApp.loadingDialog.progress(progress, loaded + ' von ' + dataCount);
                            $.ajax({
                                url: erwingoApp.options.url,
                                data: {
                                    controller: 'csv',
                                    action: 'stepImport/' + type,
                                    data: row
                                },
                                success: function (response) {
                                    erwingoApp.loadingDialog.console(response);
                                    if (data.length != 0) {
                                        exportStep(data, type, dataCount);
                                    } else {
                                        erwingoApp.loadingDialog.stop();
                                    }
                                }
                            });
                        };
                        Swal.fire({
                            title: i18next.t('Start import?'),
                            text: i18next.t('The import of') + dataCount + i18next.t('Data sets are available.'),
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: i18next.t('Confirm'),
                            cancelButtonText: i18next.t('Cancel')
                        }).then(function (result) {
                            if (result.isConfirmed) {
                                erwingoApp.loadingDialog.start();
                                exportStep(results.data, type, dataCount);
                            }
                        });
                    },
                    error: undefined,
                    skipEmptyLines: true
                }
            });
        });
    }
    // Neuer training
    if (typeof $.fn.fileupload == 'function') {
        $(target).find('input[type="file"]:not("[data-parse]")').fileupload({
            dataType: 'json',
            url: erwingoApp.options.url,
            limitMultiFileUploads: 1,
            sequentialUploads: true,
            start: function () {
                erwingoApp.loadingDialog.start();
            },
            stop: function (e, data) {
                erwingoApp.loadingDialog.stop();
            },
            progressall: function (e, data) {
                var progress = parseInt(data.loaded / data.total * 100, 10);
                erwingoApp.loadingDialog.progress(progress, progress + '%');
            },
            add: function (e, data) {
                var success = function (result, textStatus, jqXHR) {
                    erwingoApp.processJsonResult(result);
                };
                if ($(this).data("confirm")) {
                    Swal.fire({
                        title: i18next.t('Start import?'),
                        html: $(this).data("confirm"),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: i18next.t('Confirm'),
                        cancelButtonText: i18next.t('Cancel'),
                        preConfirm: function () {
                            // Return form data from swal
                            var formData = {};
                            $.each($('#swal2-content').find('form').serializeArray(), function (idx, input) {
                                formData[input['name']] = input['value'];
                            });
                            return formData;
                        }
                    }).then(function (result) {
                        if (result.isConfirmed) {
                            // Add form data from swal to post data
                            var fileupload = $(e.target).data('blueimpFileupload');
                            fileupload.options.formData = $.extend({}, fileupload.options.formData, result.value);
                            var jqXHR = data.submit()
                                .success(success)
                                .error(function (jqXHR, textStatus, errorThrown) {
                                    console.log("error fileupload add", jqXHR, jqXHR.responseText, textStatus, errorThrown);
                                })
                                .complete(function (result, textStatus, jqXHR) {
                                });
                        }
                    });
                } else {
                    var jqXHR = data.submit()
                        .success(success)
                        .error(function (jqXHR, textStatus, errorThrown) {
                            console.log("error fileupload add", jqXHR, jqXHR.responseText, textStatus, errorThrown);
                        })
                        .complete(function (result, textStatus, jqXHR) {
                        });
                }
            },
            done: function () {
                // Update all lists
                $("[data-api-listing]").each(function () {
                    $(this).apiListing('update');
                });
            }
        });
    }
    if (typeof $.fn.arrayinput == 'function') {
        $(target).find(".array-input").arrayinput();
    }
    if (typeof $.fn.formwatcher == 'function') {
        $(target).find(".formwatcher").formwatcher();
    }
    if (typeof $.fn.dynamictab == 'function') {
        $(target).find(".dynamictab").dynamictab({
            url: url_api,
            onSuccess: function () {
                var container = this.target;
                var $container = $(container);
                erwingoApp.loadModules(container);
            }
        });
    }

    $(document).on('click','.listButton',function(e){
        e.preventDefault();

        var data = {};
        $.each($(this).data(), function (k, v) {
            if (['number', 'string'].indexOf(typeof v) > -1) {
                data[k] = v;
            }
        });

        var req = {
            data: data,
            module: data['dtModule'],
            cl: data['dtCl'],
            fnc: data['dtFnc']
        };

        erwingoApp.ajax({
            data: {cl: data['dtCl'], fnc: data['dtFnc'], module: data['dtModule'], data: data},
            success: function (response) {
                // set current user object in session storage to enrich sentry issues with
                if (response) {
                    $target = $("#booking-form");
                    if (typeof response.ident != 'undefined') {
                        // Toggle functionality
                        if ($target.data('dynamictab-id') == response.ident) {
                            $target.text("").css({display: ""}).data('dynamictab-id', "");
                        } else {
                            $target.html(response.content).show().data('dynamictab-id', response.ident);
                        }
                    } else {
                        $target.html(response.content).show();
                    }

                    var $modal = $("#booking-form").closest(".modal");
                    if ($modal.length) {
                        $modal.modal('show');

                        if ($modal.is("[data-contained]")) {
                            $('.modal-backdrop').appendTo($modal.parent()).css("position", "absolute");
                            $('body').removeClass("modal-open").css("padding-right", "");
                        }
                    }

                    var $fw = $("#booking-form").closest(".formwatcher");
                    if ($fw.length) {
                        $fw.formwatcher("reinit");
                    }
                }
            }
        });
    });

    $(document).on('click','.listButton-consent',function(e){
        e.preventDefault();

        var data = {};
        $.each($(this).data(), function (k, v) {
            if (['number', 'string'].indexOf(typeof v) > -1) {
                data[k] = v;
            }
        });

        var req = {
            data: data,
            module: data['dtModule'],
            cl: data['dtCl'],
            fnc: data['dtFnc']
        };

        erwingoApp.ajax({
            data: {cl: data['dtCl'], fnc: data['dtFnc'], module: data['dtModule'], data: data},
            success: function (response) {
                // set current user object in session storage to enrich sentry issues with
                if (response) {

                    // if response.content is undefined
                    if (typeof response.content === 'undefined') {
                        erwingoApp.ajax({
                            data: {
                                cl: data['dtCl2'],
                                fnc: data['dtFnc2'],
                                module: data['dtModule'],
                                data: data
                            },
                            success: function success(response) {
                                location.reload()
                            }
                        });
                        return;
                    }

                    $target = $("#booking-accept-consent");
                    if (typeof response.ident != 'undefined') {
                        // Toggle functionality
                        if ($target.data('dynamictab-id') == response.ident) {
                            $target.text("").css({display: ""}).data('dynamictab-id', "");
                        } else {
                            $target.html(response.content).show().data('dynamictab-id', response.ident);
                        }
                    } else {
                        $target.html(response.content).show();
                    }

                    var $modal = $("#booking-accept-consent").closest(".modal");
                    if ($modal.length) {
                        $modal.modal('show');

                        if ($modal.is("[data-contained]")) {
                            $('.modal-backdrop').appendTo($modal.parent()).css("position", "absolute");
                            $('body').removeClass("modal-open").css("padding-right", "");
                        }
                    }

                    var $fw = $("#booking-accept-consent").closest(".formwatcher");
                    if ($fw.length) {
                        $fw.formwatcher("reinit");
                    }
                }
            }
        });
    });

    $(target).find("textarea.editor, textarea.editor-slim").each(function () {
        createEditorTiny($(this).attr('id'));
    });
    $(target).find("[data-bs-toggle='popover']").each(function () {
        var cl = $(this).data('class');
        var options = {
            html: true,
            container: "body",
            template: '<div class="popover ' + cl + '"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
        };
        if ($(this).data("popover-cl")) {
            options.html = true;
            options.delay = {show: 500};
            options.content = function () {
                return erwingoApp.dynamicPopover($(this));
            }
        }
        if (typeof $.fn.popover == 'function') {
            $(this).popover(options).on("shown.bs.popover", function () {
                // Hide on mousedown (anywhere)
                var $that = $(this);
                $("body").one("mousedown", function () {
                    $that.popover('hide');
                });
            });
        }
    });
    $(target).find('[data-bs-toggle="tooltip"]').each(function () {
        $(this).tooltip();
    });
    // For switches in listing
    $(target).find('[checked="0"]').addClass("notransition").prop("checked", false);
    $(target).find('[disabled="0"]').addClass("notransition").prop("disabled", false);
    setTimeout(function () {
        $('.notransition').removeClass("notransition");
    }, 100);
    $(target).find(".slideout-toggle").on('click', function () {
        $(this).toggleClass("clicked");
        var id = $(this).attr("href");
        if (id === '#' && $(this).attr('data-id') === 'refreshwindow') return true;
        if (typeof id !== 'undefined' && id !== "javascript:" && id !== "javascript;"
            && id !== "javascript:void(0)") {
            if (!$(this).hasClass("clicked")) {
                $(id).parent().removeClass("hover");
            } else {
                $(id).addClass("open");
            }
        }
        return false;
    });
    $(target).find(".slideout-toggle").on('click', function () {
        var $elem = $(this);
        $elem.parent().siblings().each(function () {
            var $sibling = $(this).find('.slideout-toggle');
            $sibling.removeClass('clicked');
            var id = $sibling.attr("href");
            setTimeout(function () {
                $(id).removeClass("open");
                if (!$(id).parent().find("> .open").length) {
                    $(id).closest(".slideout-menus").css("height", "");
                }
            }, 100);
        });

        var id = $(this).attr("href");
        if (id === '#' && $(this).attr('data-id') === 'refreshwindow') return true;
        if (typeof id !== 'undefined' && id !== "javascript:" && id !== "javascript;"
            && id !== "javascript:void(0)") {
            if (!$elem.hasClass("clicked")) {
                setTimeout(function () {
                    $(id).removeClass("open");
                    if (!$(id).parent().find("> .open").length) {
                        $(id).closest(".slideout-menus").css("height", "");
                    }
                }, 100);
            } else {
                $(id).addClass("open");
                $(id).closest(".slideout-menus").addClass("open");
                $(id).closest(".slideout-menus").css("height", $(id).outerHeight());
                // automatically scroll to top of panel if context menu is expanded
                let $panel = $(id).closest(".autoHeight")[0]
                if ($panel) {
                    $panel.scrollTop = 0
                }
            }
        }
        setTimeout(function () {
            erwingoApp.calcHeight();
        }, 300);
    });
    /*$(target).on('click', function (e) {
        if (!($(e.target).parents(".slideout-menus").length || $(e.target).is(".select2-selection__choice__remove,.swal2-confirm"))) {
            $(target).find(".slideout-toggle").each(function () {
                $(this).removeClass("clicked");
                let target = $(this).attr("href");
                setTimeout(function () {
                    if (!target.startsWith("javascript") && $(target).length > 0) {
                        $(target).removeClass("open");
                        if (!$(target).parent().find("> .open").length) {
                            $(target).closest(".slideout-menus").css("height", "");
                        }
                    }
                }, 50);
            })
        }
    });*/
    if (typeof $.fn.filterlist == 'function') {
        $(target).find(".filterlist").filterlist();
    }
    /**
     * @depraced
     * @todo remove from all templates and this file
     */
    if (typeof $.fn.ajaxform == 'function') {
        $(target).find("[data-api-form]").ajaxform({
            url: url_api,
            controller: 'Form',
            action: $(this).data("apiForm"),
            onSuccess: function (data) {
                // Update all lists
                $("[data-api-listing]").each(function () {
                    $(this).apiListing('update');
                });
                if (typeof data.popup != 'undefined') {
                    $("<div></div>").window({
                        action: data.popup.action || "",
                        id: data.popup.id || "",
                        selected: data.popup.selected || "",
                        module: data.popup.module || "",
                    });
                }
                // if (typeof data.reopen != 'undefined') {
                    // Close winow
                    // Open window mit ID data.reopen
                // }
                if (typeof data.redirect != "undefined") {
                    if (data.redirect == "?")
                        window.location.reload();
                    else
                        window.location.href = data.redirect;
                    return;
                }
            }
        });
    }
    $(target).find(".widget-chart").each(function () {
        erwingoApp.updateChart($(this));
    });
    let users_with_exit_date = 0;
    $(target).find(".listactions-check").change(function () {
        let dataTypeId = $(this).attr('data-type-id');
        let users = {};
        if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem(dataTypeId)) {
            users = JSON.parse(sessionStorage.getItem(dataTypeId));
        }
        var checked = $(this).is(":checked");
        let userId = $(this).val();

        // Check if user is still active
        let leaving_date = $(this).attr('data-leaving-date');
        if (leaving_date !== '') {
            let date = moment(leaving_date, 'DD.MM.YYYY');
            let datedash = date.format('YYYY-MM-DD');
            let today = new Date();
            if (today > new Date(datedash)) {
                checked = false;
                users_with_exit_date++;
            }
        }

        if ($(this).attr('data-booking-user-id')) {
            userId = $(this).attr('data-booking-user-id');
        }
        if (checked) {
            users[userId] = $(this).attr('data-user-name')
        }
        else {
            if (users[userId]) {
                delete users[userId];
            }
        }
        sessionStorage.setItem('users_with_exit_date', users_with_exit_date)
        sessionStorage.setItem(dataTypeId, JSON.stringify(users))

        let openedPopups = [];
        if (typeof sessionStorage !== 'undefined') {
            if (sessionStorage.getItem('openedpopups')) {
                openedPopups = JSON.parse(sessionStorage.getItem('openedpopups'));
            }

            if (openedPopups.indexOf(dataTypeId) === -1) {
                openedPopups.push(dataTypeId);
            }
        }
        sessionStorage.setItem('openedpopups', JSON.stringify(openedPopups))

        var same = true;
        $(this).closest("tr").siblings().each(function () {
            if ($(this).find(".listactions-check").is(":checked") != checked) {
                same = false;
            }
        });
        if (same) {
            $(this).closest("table").find("tfoot .listactions-checkall").prop("indeterminate", false).prop("checked", checked);
        } else {
            $(this).closest("table").find("tfoot .listactions-checkall").prop("checked", true).prop("indeterminate", true);
        }
    });
    $(target).find("tfoot .listactions-checkall").change(function () {
        var checked = $(this).is(":checked");
        $(this).closest("table").find("tr .listactions-check").prop("checked", checked).trigger("change");
        let checker2 = $(".listactions-checkall2");
        if (checker2.length > 0) {
            checker2.prop("indeterminate", false);
            checker2.prop("checked", false);
        }
    });
    $(target).find("tfoot .listactions-checkall2").change(function () {
        var checked = $(this).is(":checked");
        $(this).closest("table").find("tr .listactions-check").prop("checked", checked).trigger("change");
        let checker1 = $(".listactions-checkall");
        if (checker1.length > 0) {
            checker1.prop("indeterminate", false);
            checker1.prop("checked", false);
        }
    });
    $(target).find("[data-check-all]").change(function () {
        var targetClass = $(this).data("checkAll") + ":not([disabled])";
        var checked = $(this).is(":checked");
        $(targetClass).prop("checked", checked).trigger("change");
    });
    if (typeof $.fn.sortable == 'function') {
        $(target).find(".sortable").each(function () {
            var options = {
                items: "> :not(.not-sortable)",
                handle: '.drag-handle'
            };
            var connect = $(this).data('sortable-connect');
            if (connect) {
                $(this).addClass(connect);
                options.connectWith = "." + connect;
            }
            $(this).sortable(options);
        });
    }
};

function createEditorTiny(elementId) {
    tinymce.init({
        selector: 'textarea#'+elementId,
        height: tinymceeditorheight,
        menubar: tinymcemenubar,
        content_style: tinymcedefaultcontentstyle,
        toolbar_mode: tinymcetoolbarmode,
        branding: tinymcebranding,
        language: tinymcelanguage,
        plugins: tinymceplugins,
        toolbar: tinymcetoolbar,
        image_title: tinymceimagetitle,
        automatic_uploads: tinymceautomaticuploads,
        help_tabs: [
            'shortcuts', // the default shortcuts tab
            'keyboardnav', // the default keyboard navigation tab
        ],
        file_picker_types: 'image',
        file_picker_callback: function (cb, value, meta) {
            var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.onchange = function () {
                var file = this.files[0];

                var reader = new FileReader();
                reader.onload = function () {
                    var id = 'blobid' + (new Date()).getTime();
                    var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                    var base64 = reader.result.split(',')[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);
                    cb(blobInfo.blobUri(), { title: file.name });
                };
                reader.readAsDataURL(file);
            };
            input.click();
        },
        setup:function(editor) {
            editor.on('input change keydown keyup', function(e) {
                $("#"+editor.id).val(editor.getContent());
            });
        }
    });
}

// Reload page if user got logged out
var user = undefined;

function checkUser(userType, timeout) {
    erwingoApp.ajax({
        data: {cl: "AccessControl", fnc: "checkLoginStatus", userType: userType, auto: true},
        ignoreLoadingIndicator: true,
        success: function (response) {
            // set current user object in session storage to enrich sentry issues with
            if (response) {
                Sentry.setUser({
                    id: response.id,
                    userType: response.userType,
                })
            } else {
                Sentry.setUser(null)
            }
            // Page reload is handled by erwingo.processjsonresults.js
        },
        error: function (err) {
            Sentry.setUser(null)
        }
    });
    return setTimeout(function () {
        checkUser(userType, timeout);
    }, timeout);
}

function initSentry() {
    erwingoApp.ajax({
        data: {cl: "Frontend", fnc: "getSentrySetupInformation"},
        success: function (setup) {
            if (setup) {
                setSentryDsn(setup)
            }
        }
    });
}

function setSentryDsn(setup) {
    Sentry.init({
        dsn: setup.dsn,
        release: setup.release,
        integrations: [new SentryIntegrations.BrowserTracing()],
    })
}

/* Init Sentry to capture javascript errors */
initSentry()
// If currently a user is logged in
if (logged) {
    checkUser(erwingoApp.options.ajaxInitiator === 'backend' ? 'admin' : 'employee', 15000);
}
// Browser Update
// https://browser-update.org/de/customize.html
var $buoop = {
    required: {
        // edge, -4 versions from most recent
        e: -4,
        // firefox, -3 versions from most recent
        f: -3,
        // opera, -3 versions from most recent
        o: -3,
        // safari, -1 versions from most recent
        s: -1,
        // chrome, -3 versions from most recent
        c: -3,
    },
    // language
    l: "de",
    // hide "ignore" button
    noclose: true,
    // do not allow users to hide this message forever
    no_permanent_hide: true,
    // notify users of browsers with known security issues!
    insecure: true,
    // notify users of browsers without updates (outdated)
    unsupported: true,
    style: "bottom",
    api: 2021.03,
    // callback function on message show
    onshow: function (infos) {
    }
}

function $buo_f() {
    var e = document.createElement("script");
    e.src = "//browser-update.org/update.min.js";
    document.body.appendChild(e);
}

try {
    document.addEventListener("DOMContentLoaded", $buo_f, false)
} catch (e) {
    window.attachEvent("onload", $buo_f)
}
