/*

 * Description: jQuery-Plugin zum Steuern von Formular-Aktionen (z.B. Ausblenden eines Feldes, wenn eine Checkbox geklickt wird)
 */

(function ($) {

    $.fn.arrayinput = function (options) {

        this.each(function () {

            var that = this;

            this.settings = $.extend({

                btnTemplate: '<button class="btn"></button>',
                addBtnClass: 'btn-primary',
                addBtnIcon: 'fa fa-plus',
                removeBtnClass: 'btn-danger',
                removeBtnIcon: 'fa fa-minus',
                btnSize: '',
                template: '',

            }, options);

            var $obj = $(this); // Parent of each row

            // Prevent double initialization
            if ($obj.hasClass("initialized")) {
                return false;
            }

            /* Overwrite settings with data attribute values */
            if (typeof $obj.data('btn-size') != 'undefined')
                this.settings.btnSize = "btn-" + $obj.data('btn-size');

            /* Replace $obj with input group containing $obj and buttons */
            var $rows = $obj.children();

            $rows.each(function (idx, val) {

                /* Create buttons */
                var $addBtn = $(that.settings.btnTemplate).addClass(['btn-add', that.settings.addBtnClass, that.settings.btnSize].join(" "));
                var $removeBtn = $(that.settings.btnTemplate).addClass(['btn-remove', that.settings.removeBtnClass, that.settings.btnSize].join(" "));

                $('<i class=""></i>').addClass(that.settings.addBtnIcon).appendTo($addBtn);
                $('<i class=""></i>').addClass(that.settings.removeBtnIcon).appendTo($removeBtn);

                var $grp = makeInputGroup($(this), $addBtn, $removeBtn)
                    .addClass("array-input-row")
                    .css({marginTop: "10px"})
                    .appendTo($obj);

                // Get first row as template for new rows
                if (idx == 0) {
                    that.settings.template = $grp.wrap("<div></div>").parent().html();
                    $grp.unwrap();
                }

                // Add random index to name attribute
                addRandomIdx($grp);

                /* Add add and remove button events */
                $addBtn.on('click', addButtonClick);
                $removeBtn.on('click', removeButtonClick);

            });

            /* Hide close button if this is the first input of the array-input */
            checkRemovable($obj);

            $obj.data("arrayinput", {settings: this.settings})
                .addClass("array-input-parent initialized");

        });


        /* Functions */

        /*
         * Event function for add button click
        */
        function addButtonClick() {

            var $obj = $(this).closest(".array-input-parent");
            var $grp = $(this).closest(".array-input-row");

            var $clone = $($obj.data("arrayinput").settings.template);

            addRandomIdx($clone);

            $clone.find("input").val("");
            $clone.find("select option").prop("selected", false).first().prop("selected", true);
            $clone.find("select[data-auto-complete] option").remove();

            $clone.find(".has-feedback").removeClass('has-error has-success has-feedback has-order-request-error').find(".form-control-feedback").remove();
            $clone.find(".error-message").text("");

            $grp.find(".keep-value").each(function (idx) {
                console.log(idx, $clone.find(".keep-value")[idx]);
                $($clone.find(".keep-value")[idx]).val($(this).val());
            });

            $grp.after($clone);

            $clone.find(".btn-add").on('click', addButtonClick);
            $clone.find(".btn-remove").on('click', removeButtonClick);

            if (typeof erwingoApp.loadModules === "function") {
                erwingoApp.loadModules($clone.get(0));
            }

            checkRemovable($grp.parent());

            return false;

        }

        /*
         * Event function for remove button click
        */
        function removeButtonClick($elem) {

            var $wrap = $(this).closest(".array-input-row"),
                $parent = $wrap.parent();

            $wrap.remove();

            checkRemovable($parent);

            return false;

        }

        /*
         * Hide the remove button, if only one item is left.
         * Otherwise the input might be removed completely and unreversably.
        */
        function checkRemovable($parent) {

            // console.log($parent.attr("class"));

            var $items = $parent.find(".array-input-row");

            $items.find(".btn-remove").parent().removeClass("hide");

            if ($items.length == 1) {
                $items.find(".btn-remove").parent().addClass("d-none");
            }

        }

        /*
         * Creates an input group using the jQuery-elements that are passed to the function
        */
        function makeInputGroup() {

            var $contents = arguments,
                $grp = $('<div class="input-group"></div>');

            $.each($contents, function (k, $elem) {
                if ($elem.prop("tagName").toLowerCase() == "button") {
                    var $wrap = $('<span class="input-group-btn"></span>');
                    $wrap.append($elem);
                    $grp.append($wrap);
                } else {
                    $grp.append($elem);
                }
            });

            return $grp;

        }

        /*
         * Adds a random index to empty name array brackets (name="foo[]")
        */
        function addRandomIdx($elem) {

            var idx = Math.random().toString(36).substring(2);
            $elem.find("[name]").each(function () {
                var name = $(this).attr("name");
                var newName = name ? name.replace(/\[\]/, "[" + idx + "]") : '';
                $(this).attr("name", newName);
            });

        }


    }

}(jQuery));
