erwingoApp.getCookie = function (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

erwingoApp.setCookie = function (cname, cvalue, exdays) {
    var exdays = typeof exdays == 'undefined' ? 365 : exdays;
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

erwingoApp.checkCookie = function () {
    var user = erwingoApp.getCookie("username");
    if (user != "") {
        alert(i18next.t('Welcome again') + user);
    } else {
        user = prompt(i18next.t('Please enter your name:'), "");
        if (user != "" && user != null) {
            erwingoApp.setCookie("username", user, 365);
        }
    }
};
