(function ($) {

    var zIndex = 100;
    var lastPosition = {x: 0, y: 0};

    $.widget("ui.window", {

        version: "1",
        options: {
            warnMe: null,
            url: window.url_api, // Ajax URL
            controller: "Popup",
            module: "",
            action: "0",
            id: "0",
            selected: null,
            navigation: null,
            data: null,
            state: "default", // default|min|max|left|right
            containment: "#main",
            taskbar: "#taskbar",
            started: null,
            buttons: [
                {
                    title: "OK",
                    btnType: "primary"
                }
            ],
            height: 600,
            width: 992,
            maxHeight: null,
            maxWidth: null,
            minHeight: 300,
            minWidth: 400,
            position: {
                my: "center",
                at: "center",
                of: window,
                collision: "fit",
                // Ensure the titlebar is always visible
                using: function (pos) {
                    var topOffset = $(this).css(pos).offset().top;

                    if (topOffset < 0) {
                        $(this).css("top", pos.top - topOffset);
                    }
                }
            },
            title: null,

            // Callbacks
            beforeClose: null,
            close: null,
            drag: null,
            dragStart: null,
            dragStop: null,
            focus: null,
            open: null,
            resize: null,
            resizeStart: null,
            resizeStop: null,
            load: null,

            // Breakpoints (for class assignment depending on window width)
            breakpoints: [
                {mediaQuery: "", class: "window-sm"},
                {mediaQuery: "min-width: 576x", class: "window-sm"},
                {mediaQuery: "min-width: 768px", class: "window-md"},
                {mediaQuery: "min-width: 992px", class: "window-lg"},
                {mediaQuery: "min-width: 1200px", class: "window-xl"},
                {mediaQuery: "min-width: 1400px", class: "window-xxl"},
            ],
            extra: "0",

        },

        // Public Function
        focus: function () {

            var that = this;

            if (that.options.state == 'min') {
                that.options.state = 'normal';
                that._state();
            }

            that.element.css("z-index", zIndex++)

        },

        _saveSession: function () {

            var that = this;

            // console.log("Save Session start");

            position = {
                my: that.options.position.my,
                at: that.options.position.at,
                collision: that.options.position.collision,
            }

            $.ajax({

                url: that.options.url,

                data: {
                    cl: 'Session',
                    fnc: 'save',
                    data: {
                        module: that.options.module,
                        action: that.options.action,
                        id: that.options.id,
                        selected: that.options.selected,
                        state: that.options.state,
                        position: position,
                        height: that.options.height,
                        width: that.options.width,
                    },
                },

                success: function (text) {

                    // console.log(text);

                }

            });

            // console.log("Save Session stop");

        },

        close: function () {

            this.options.state = "close";

            this._saveSession();

            this._state()

        },

        reload: function (reopenTabs) {

            var that = this;
            var reopenTabs = reopenTabs || [];

            // console.log('reopentabs', reopenTabs);

            that._request({
                cl: that.options.controller,
                module: that.options.module,
                id: that.options.id,
                action: that.options.action,
                selected: that.options.selected
            }, undefined, function ($window) {

                $.each(reopenTabs, function (k, v) {
                    var $tab = $window.find('.tab-content').eq(k).find('> .tab-pane').eq(v);
                    var id = $tab.attr('id');
                    // console.log('tab', $tab, id);
                    if ($('[href="#' + id + '"],[data-target="#' + id + '"]').length) {
                        $('[href="#' + id + '"],[data-target="#' + id + '"]').first().tab('show');
                    }
                });

            });

        },

        setWarnMe: function (message) {

            var that = this;

            that.options.warnMe = message;


        },

        _checkWarnMe: function (callback) {


            var _this = this;

            // console.log(_this.options.warnMe);

            if (_this.options.warnMe) {
                Swal.fire({

                    title: _this.options.warnMe,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: "OK",
                    cancelButtonText: "X",
                    // html: "",

                }).then(callback);


            } else {

                callback();

            }

        },

        _buildWindow: function (html, popupId) {

            var that = this;

            // Set Element
            this.element
                .show()
                .appendTo(this.options.containment)
                .attr('id', popupId)
                .addClass("window")
                .css("z-index", zIndex++)
                .attr('tabindex', '-1').key("esc", function () { /* that.close(); das nervt */
            }) // Shortcuts
                .append('<div class="window-content autoHeight calcHeight"></div>')
                .click(function () {
                    that.element.css("z-index", zIndex++);
                });

            // No containment when "var windowBounds = 0;"
            var containment = that.options.containment;
            if (typeof windowBounds != "undefined" && windowBounds == 0)
                containment = false;

            // Add UI-Widgets
            this.element
                .draggable({
                    containment: containment,
                    handle: ".panel-heading",
                    opacity: 0.35,
                    start: function (event, ui) {
                        that.element.css("z-index", zIndex++);
                        that.element.width(that.options.width);
                        that.element.height(that.options.height);
                        erwingoApp.calcHeight();
                    },
                    stop: function (event, ui) {
                        that.options.position = ui.position;
                        that._saveSession();
                    },
                    drag: function (event, ui) {
                        // Prevent window header to disappear behind header, if containment is not used in draggable
                        if (ui.position.top < 0) {
                            ui.position.top = 0;
                        }
                    }
                })
                .resizable({
                    containment: containment,
                    minHeight: that.options.minHeight,
                    minWidth: that.options.minWidth,
                    start: function (event, ui) {
                        that.element.css("z-index", zIndex++);
                    },
                    resize: function (event, ui) {
                        that._checkBreakpoints();
                        that.element.find("[data-api-listing]").each(function () {
                            $(this).apiListing('updateCellWidths');
                        });
                    },
                    stop: function (event, ui) {
                        that.options.width = ui.size.width;
                        that.options.height = ui.size.height;
                        that._checkBreakpoints();
                        that._saveSession();
                    }
                });

            that.element.on("mouseenter", ".window-positioner", function () {
                var positioner = $(this);
                setTimeout(function () {
                    if ($(".window-positioner:hover").length != 0) {
                        $(".positioner-wrap").position({
                            my: "center",
                            at: "center",
                            of: positioner
                        }).css({"opacity": 1, "visibility": "visible"});

                        $(".positioner-focus").removeClass('positioner-focus');
                        that.element.addClass("positioner-focus");
                    }
                }, 500);
            });

            $("body").on("mousemove", function () {
                if ($(".positioner-wrap:hover").length == 0 && $(".window-positioner:hover").length == 0) {
                    $(".positioner-wrap").css({"opacity": 0, "visibility": "hidden"});
                }
            });

            // Fill HTML
            this._build(html);

            this._fixWindowPosition();
            $(window).resize(this._fixWindowPosition);

        },

        _create: function () {

            // console.log("create window....");

            var that = this;

            this._request({
                cl: that.options.controller,
                module: that.options.module,
                action: that.options.action,
                id: that.options.id,
                selected: that.options.selected,
                extra: that.options.extra,
            }, true);

        },

        _request: function (request, buildWindow, callback) {

            var popupId = request.module + '_' + request.action + '_' + request.id;

            // Only if the popup doesn't exist, yet
            if (buildWindow && $("#" + popupId).length !== 0) {
                // console.log('window already open. focussing and cancelling...')
                $("#" + popupId).window('focus');
                return this.element.remove();
            }

            callback = callback || function () { };
            var that = this;

            $.ajax({

                url: that.options.url,

                data: request,

                success: function (json) {

                    var response = {};

                    try {
                        response = JSON.parse(json);
                    } catch (err) {
                        let req = request;
                        function objToString (obj) {
                            return Object.entries(obj).reduce((str, [p, val]) => {
                                return `${str}${p}::${val}\n`;
                            }, '');
                        }
                        if (typeof req === 'object') {
                            req = objToString(req);
                        }
                        let report = {"request": req, "jsError" : err.message, "codeError" : JSON.stringify(json)};
                        console.log(report);
                        Sentry.captureException(report);
                    }

                    // console.log("response....");
                    // console.log(response);

                    if (typeof response.logout != "undefined" && response.logout) {
                        erwingoApp.autologout();
                        return;
                    }

                    if (typeof response.alert != "undefined") {
                        Swal.fire('Fehler', response.alert, 'error').then(r => { /* nvm */
                        });
                    } // Alert Message

                    if (typeof response.popup != 'undefined') {

                        if (typeof buildWindow != 'undefined') {
                            that._buildWindow(response.popup.html, popupId); // Erstelle ein Div
                        } else {
                            that._build(response.popup.html, popupId);
                        }

                    }

                    // Status
                    that._state(typeof buildWindow != 'undefined');

                    erwingoApp.calcHeight();

                    callback(that.element);

                }

            });

        },

        _state: function (animate) {

            var animate = animate || false;

            var that = this;

            switch (that.options.state) {
                case 'close':
                    that.element.remove(); // Close Window
                    if ($(document).find("#main > .window").length) {
                        $(document).find("#main > .window")[0].focus();
                    }
                    break;
                case 'min':
                    that.element.appendTo(that.options.taskbar); // Move to taskbar
                    that.element.css({float: "left", position: "static"});
                    // only allow draggable if jquery ui widget has been initialized
                    if (that.element.hasClass('ui-draggable')) {
                        that.element.draggable('disable');
                    }
                    // only allow resize if jquery ui widget has been initialized
                    if (that.element.hasClass('ui-resizable')) {
                        that.element.resizable('disable');
                    }
                    that.element.find(".window-hide, .panel-body, .panel-footer").hide();
                    /*that.element.find(".window-title").css("width", "75%");*/
                    that.element.height(that.element.find(".panel-heading").outerHeight());
                    that.element.width(240);
                    break;
                case 'max':
                    if ("#" + that.element.parent().attr("id") != that.options.containment) {
                        that.element.appendTo(that.options.containment); // Move to Main
                    }
                    that.element.css({float: "none", position: "absolute"});
                    // only allow draggable if jquery ui widget has been initialized
                    if (that.element.hasClass('ui-draggable')) {
                        that.element.draggable('disable');
                    }
                    // only allow resize if jquery ui widget has been initialized
                    if (that.element.hasClass('ui-resizable')) {
                        that.element.resizable('disable');
                    }
                    that.element.css({top: "0px", left: "0px"});
                    that.element.css({width: "100%", height: "100%"});
                    that.element.find(".window-hide, .panel-body").show();
                    that.element.find(".window-title").removeAttr("style");
                    break;
                default:
                    if ("#" + that.element.parent().attr("id") != that.options.containment) {
                        that.element.appendTo(that.options.containment); // Move to Main
                    }
                    that.element.css({float: "none", position: "absolute"});

                    // only allow resize if jquery ui widget has been initialized
                    if (that.element.hasClass('ui-resizable')) {
                        that.element.resizable('enable');
                    }

                    that.element.width(that.options.width);
                    that.element.height(that.options.height);
                    that.element.find(".window-hide, .panel-body, .panel-footer").show();
                    that.element.find(".window-title").removeAttr("style");

                    if (typeof (that.options.position.top) != 'undefined') {
                        that.element.css({
                            top: that.options.position.top + "px",
                            left: that.options.position.left + "px"
                        });
                    } else {
                        that.element.position(that.options.position);
                    }

                    that.options.position.top = that.element.position().top;
                    that.options.position.left = that.element.position().left;

                    // only allow draggable if jquery ui widget has been initialized
                    if (that.element.hasClass('ui-draggable')) {
                        that.element.draggable('enable');
                    }

                    if (animate) {
                        that.element.removeClass("no-animation");
                    }

                    that.element.focus();

            }

            that._buildStateButtons();

            that._checkBreakpoints();

            erwingoApp.calcHeight();

            that._fixWindowPosition()

            that._saveSession();

        },

        _init: function () {

            var that = this;

            that.element.on("click", "[data-select]", function () {

                var selected = $(this).data("select");

                that._checkWarnMe(function () {

                    that.options.selected = selected;

                    that._request({
                        cl: that.options.controller,
                        module: that.options.module,
                        id: that.options.id,
                        action: that.options.action,
                        selected: that.options.selected
                    });

                });

            });

            that.element.on("click", "[data-state]", function () {

                var state = $(this).data("state");

                if (state == 'close') {

                    that._checkWarnMe(function () {

                        that.options.state = state;

                        that._state();

                    });

                } else {

                    that.options.state = state;

                    that._state();

                }

            });

            that.element.on("dblclick", ".panel-heading", function () {

                if (that.options.state == 'default') {

                    that.options.state = 'max';

                } else {

                    that.options.state = 'default';

                }

                that._state();

            });

            if (!$(".positioner-wrap").length) {
                $("body").append('<div class="positioner-wrap" style="z-index: 9999;">'
                    + '<div class="positioner-buttons positioner-buttons-outer">'
                    + '<a data-pos="tl"><i class="fa fa-arrow-left fa-rotate-45"></i></a>'
                    + '<a data-pos="tr"><i class="fa fa-arrow-up fa-rotate-45"></i></a>'
                    + '<a data-pos="bl"><i class="fa fa-arrow-down fa-rotate-45"></i></a>'
                    + '<a data-pos="br"><i class="fa fa-arrow-right fa-rotate-45"></i></a>'
                    + '</div>'
                    + '<div class="positioner-buttons positioner-buttons-inner">'
                    + '<a data-pos="l"><i class="fa fa-arrow-left"></i></a>'
                    + '<a data-pos="r"><i class="fa fa-arrow-right"></i></a>'
                    + '</div>'
                    + '</div>');

                $(".positioner-wrap").on("click", "[data-pos]", function () {

                    $(".positioner-wrap").css({"opacity": 0, "visibility": "hidden"});

                    var positionerWindow = $(".positioner-focus");
                    positionerWindow.trigger("click").removeClass(".positioner-focus");

                    var pos = $(this).data("pos");

                    var pw = positionerWindow.parent().width(),
                        ph = positionerWindow.parent().height();

                    that = positionerWindow.data('uiWindow');

                    if (that.options.state != "max") {
                        that.options.width = positionerWindow.width();
                        that.options.height = positionerWindow.height();
                    }

                    that.options.state = 'default';
                    that._state();

                    switch (pos) {
                        case "tl":
                            positionerWindow.css({width: "50%", height: "50%"});
                            positionerWindow.css({top: "0px", left: "0px"});
                            break;
                        case "tr":
                            positionerWindow.css({width: "50%", height: "50%"});
                            positionerWindow.css({top: "0px", left: pw - positionerWindow.width()});
                            break;
                        case "bl":
                            positionerWindow.css({width: "50%", height: "50%"});
                            positionerWindow.css({top: ph - positionerWindow.height(), left: "0px"});
                            break;
                        case "br":
                            positionerWindow.css({width: "50%", height: "50%"});
                            positionerWindow.css({
                                top: ph - positionerWindow.height(),
                                left: pw - positionerWindow.width()
                            });
                            break;
                        case "l":
                            positionerWindow.css({width: "50%", height: "100%"});
                            positionerWindow.css({top: "0px", left: "0px"});
                            break;
                        case "r":
                            positionerWindow.css({width: "50%", height: "100%"});
                            positionerWindow.css({top: "0px", left: pw - positionerWindow.width()});
                            break;
                    }

                    erwingoApp.calcHeight();

                    that._saveSession();

                });
            }

        },

        _buildStateButton: function (state, type, icon, title) {

            return '<li><a href="javascript:void(0);" data-state="' + state + '" class="right-side" title="' + title + '"><i class="' + icon + '"></i></a></li>';

        },

        _buildPositioner: function (icon) {

            return '<li class="window-positioner">'
                + '<a href="javascript:void(0);">'
                + '<i class="' + icon + '"></i>'
                + '</a>'
                + '</li>';

        },

        _buildStateButtons: function () {

            var that = this;
            var html = '';

            switch (that.options.state) {
                case 'min':
                    html += that._buildStateButton('default', 'primary', 'fa fa-clone', '');
                    html += that._buildStateButton('close', 'danger', 'fa fa-times', '');
                    break;
                case 'max':
                    html += that._buildPositioner('fa fa-dot-circle-o');
                    html += that._buildStateButton('min', 'primary', 'fa fa-minus', '');
                    html += that._buildStateButton('default', 'primary', 'fa fa-clone', '');
                    html += that._buildStateButton('close', 'danger', 'fa fa-times', '[ESC]');
                    break;
                default:
                    html += that._buildPositioner('fa fa-dot-circle-o');
                    html += that._buildStateButton('min', 'primary', 'fa fa-minus', '');
                    html += that._buildStateButton('max', 'primary', 'fa fa-clone', '');
                    html += that._buildStateButton('close', 'danger', 'fa fa-times', '[ESC]');
                    break;
            }

            that.element.find(".window-state").html(html);

        },

        _build: function (html) {

            var that = this;

            that.element.find(".window-content").html(html);
            that.element.find(".window-content").children().addClass("autoHeight calcHeight");
            that.element.find(".panel-body").addClass("autoHeight");
            that.element.find(".panel-heading").addClass("fixedHeight");
            that.element.find(".panel-footer").addClass("fixedHeight");

            erwingoApp.loadModules(that.element);

            erwingoApp.calcHeight();

            that._fixWindowPosition()

            return true;

        },

        _checkBreakpoints: function () {

            var that = this;

            if (typeof that.options.breakpoints_compiled == 'undefined') {
                that._compileBreakpoints()
            }

            var width = that.element.width();
            $.each(that.options.breakpointsCompiled, function (k, breakpt) {

                that.element.removeClass(breakpt.class);
                var statement = breakpt.condition.replace("{width}", width);

                if (eval(statement) === true) {
                    that.element.addClass(breakpt.class);
                }

            });

        },

        _compileBreakpoints: function () {

            var that = this;
            var breakpts = that.options.breakpoints;

            var re = /(min|max)-width: ?([0-9]+)px/g;

            var compiled = [];
            $.each(breakpts, function (k, breakpt) {
                var m;
                if (breakpt.mediaQuery == "")
                    compiled.push({
                        condition: "1==1",
                        class: breakpt.class,
                    });
                else while (m = re.exec(breakpt.mediaQuery)) {
                    var comp = m[1] == 'min' ? '>=' : m[1] == 'max' ? '<=' : '===';
                    var width = m[2];
                    compiled.push({
                        condition: "{width}" + comp + width,
                        class: breakpt.class,
                    });
                }
            });

            that.options.breakpointsCompiled = compiled;

        },

        _fixWindowPosition: function () {

            var that = this;
            setTimeout(function () {
                if (that.element && that.element[0].offsetTop <= 0) {
                    that.element.css("top", "0px");
                }
            }, 100);

        }

    });

})(jQuery);
