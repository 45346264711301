/**
 * File: jquery.ajaxform.js
 * Version: 1.2
 * @deprecated
 */

(function ($) {

    $.fn.ajaxform = function (options) {

        var count = 0;

        // Falls mehrere Formulare vorhanden sind
        this.each(function () {

            var settings = $.extend({

                id: 0,
                autoSave: false,
                controller: 'form',
                module: 'erwingo',
                fncOverride: null,
                fnc: '',
                cl: '',
                postKey: 'option',
                postIdKey: 'id',
                postDataKey: 'data',
                prefix: '',
                url: 'ajax.php',
                icon: 'icon',
                redirect: '',
                closeParent: '.ajaxform-close-parent', // Selektor für parent ( $(this).closest(closeParent) ), das nach dem Speichern ausgeblendet werden soll
                status: {
                    edit: 'ajaxform-edit',
                    saving: 'ajaxform-saving',
                    success: 'ajaxform-success',
                    error: 'ajaxform-error',
                },
                primaryButton: null,

                dataPost: {},

                onChange: function () {
                },
                onUpdate: function () {
                },
                onSuccess: function () {
                },
                onError: function () {
                },

                target: this,

            }, options);

            var obj = $(this);

            // Settings können via Attribut geladen werden
            if (typeof obj.attr("data-cl") != "undefined") {
                settings.cl = obj.attr("data-cl");
            }

            // Settings können via Attribut geladen werden
            if (typeof obj.attr("data-api-form") != "undefined") {
                settings.prefix = obj.attr("data-api-form");
            }

            // Settings können via Attribut geladen werden
            if (typeof obj.attr("data-action") != "undefined") {
                settings.prefix = obj.attr("data-action");
            }
            if (typeof obj.attr("data-redirect") != "undefined") {
                settings.redirect = obj.attr("data-redirect");
            }

            if (typeof obj.data("autoSave") != "undefined") {
                settings.autoSave = true; // Automatisches Speichern onChange
            } else {
                settings.autoSave = false; // Automatisches Speichern onChange
            }

            // Settings können via Attribut geladen werden
            if (typeof obj.attr("data-module") != "undefined") {
                settings.module = obj.attr("data-module");
            }

            if (typeof obj.attr("data-fnc") != "undefined") {
                settings.fnc = obj.attr("data-fnc");
            }

            if (typeof obj.attr("data-close-parent") != "undefined") {
                settings.closeParent = obj.attr("data-close-parent");
            }

            // Ein Icon zeigt den Status der Form an
            var icon = settings.prefix + settings.icon + '-' + settings.id + '-' + count;
            obj.find('icon').attr('id', icon).attr('class', settings.status.success);

            // Markiert nicht valide Eingaben, die von der Ajax Datei zurück kommen
            function validateForm(error) {

                obj.find('input:not([type="file"]):not([type="hidden"]):not([type="date"]):not([type="number"]),textarea,select').filter(":visible").each(function () {

                    //alert($(this).attr("type"));

                    $(this).attr("data-original-title", "");

                    $(this).closest(".form-group").removeClass('has-error has-feedback has-success');

                    $(this).parent().find('span.form-control-feedback').remove();

                    name = $(this).attr("name");
                    if (!name || !name.length) return;

                    if (typeof error != "undefined" && typeof error[name] != "undefined") {

                        $(this).attr("data-original-title", error[name]);

                        $(this).closest(".form-group").addClass('has-error has-feedback');

                        $(this).parent().append('<span class="fa fa-times form-control-feedback"></span>');

                    } else {

                        $(this).closest(".form-group").addClass('has-success has-feedback');

                        $(this).parent().append('<span class="fa fa-check form-control-feedback"></span>');

                    }

                });

            }

            // Durchsucht Form und speichert die Daten
            function getData(action) {

                if (obj.hasClass("serialize-form")) {

                    /**
                     * Form data with POST, routing with GET
                     */

                    settings.dataPost = obj.serializeArray();

                } else {

                    var name = '';
                    var value = '';
                    var data = {};

                    if (typeof action != 'undefined') {

                        data['action'] = action;

                    }

                    obj.find("input,select,textarea").each(function () {

                        if ($(this).hasClass("ignore"))
                            return

                        var tagName = $(this).prop('tagName').toLowerCase();
                        name = $(this).attr("name") || "";
                        value = $(this).val();

                        // If input is a datepicker/datetimepicker, then get the DateTime format as value
                        if (typeof $(this).data("DateTimePicker") != 'undefined' && $(this).data("DateTimePicker").date() != null) {
                            value = $(this).data("DateTimePicker").date().format("YYYY-MM-DD hh:mm:ss");
                        }

                        if (!name.length)
                            return;

                        name = name.split("#").shift();

                        if (($(this).attr("type") == "radio" || $(this).attr("type") == "checkbox") && !$(this).prop("checked"))
                            return;

                        if ($(this).closest(".no-data").length == 0) {

                            if (typeof $(this).attr("data-key") != 'undefined') {

                                var key = $(this).attr("data-key");

                                if (typeof data[name] == 'undefined') {

                                    data[name] = {};

                                    data[name][key] = value;

                                } else {

                                    if (typeof data[name][key] == 'undefined') {

                                        data[name][key] = value;

                                    } else {

                                        if (typeof data[name][key] == 'string') {

                                            data[name][key] = [data[name][key]];

                                        }

                                        data[name][key].push(value);

                                    }

                                }

                            } else {

                                if (typeof data[name] == 'undefined') {

                                    data[name] = value;

                                } else {

                                    // Erklärung der Abfrage im OR-Teil:
                                    // Wenn es mehere <select multiple></select> Felder gibt, ist 'value' jeweils ein Array
                                    // Daher checken ob schon ein Array gespeichert ist UND der erste Eintrag davon ein String ist (sonst würde sich die Dimensionalität des Arrays jedesmal vergrößern)
                                    console.log(name, data[name]);
                                    if (typeof data[name] == 'string' || (tagName == "select" && data[name].constructor === Array && typeof data[name][0] == 'string')) {

                                        data[name] = [data[name]];

                                    }

                                    data[name].push(value);

                                }

                            }

                        }

                    });

                    settings.dataPost[settings.postDataKey] = data;

                }

            }

            // Sendet Inhalt per Ajax
            function postData() {

                //settings.dataPost[settings.postIdKey] = settings.id;
                //settings.dataPost[settings.postKey] = settings.prefix+"postData";

                changeStatus("wait");

                if (obj.hasClass("serialize-form")) {

                    /**
                     * Form data with POST, routing with GET
                     */

                    getParams = {};

                    if (settings.cl == "") {
                        getParams['cl'] = settings.controller;
                        getParams['action'] = settings.prefix;
                    } else {
                        getParams['cl'] = settings.cl;
                    }

                    getParams['module'] = settings.module;
                    getParams['fnc'] = settings.fnc;

                    url = url_api + "?" + $.param(getParams);

                } else {

                    if (settings.cl == "") {
                        settings.dataPost['cl'] = settings.controller;
                        settings.dataPost['action'] = settings.prefix;
                    } else {
                        settings.dataPost['cl'] = settings.cl;
                    }

                    settings.dataPost['module'] = settings.module;

                    if (settings.fncOverride != null) {

                        settings.dataPost['fnc'] = settings.fncOverride;
                        settings.fncOverride = null;

                    } else {

                        settings.dataPost['fnc'] = settings.fnc;

                    }

                    //settings.dataPost['fnc'] = settings.fncOverride;

                    //console.log(settings.dataPost);

                    url = url_api;

                }

                settings.onUpdate();

                //alert(print_r(settings.dataPost));

                console.log(settings.dataPost);

                erwingoApp.ajax({

                    url: url,

                    data: settings.dataPost,

                    success: function (data) {

                        //if (typeof data.redirect != "undefined"){ window.location=gentext(settings.redirect,settings.dataPost.data); }

                        // Automatisches Ausfüllen von Formularen (z.B. Status E-Mail)

                        if (typeof data.trigger != "undefined") {
                            $(data.trigger).click();
                        }

                        if (typeof data.fill != "undefined") {
                            $.each(data.fill, function (key, value) {
                                obj.find('[name="' + key + '"]').val(value);
                            });
                        }

                        if (data.pass == false) {

                            changeStatus("error");
                            settings.onError(data);

                            validateForm(data.error);

                            if (settings.autoSave == false) {

                                console.log(data.message);

                                if (typeof data.message != 'undefined') {
                                    Swal.fire({
                                        title: data.message.title,
                                        text: data.message.text,
                                        icon: data.message.icon,

                                    });
                                } else {
                                    Swal.fire({title: 'Fehler', icon: 'error', timer: 2000});
                                }

                            }

                        } else {

                            // remove has-success has-feedback and icons
                            obj.find("input,select,textarea")
                                .closest(".form-group")
                                .removeClass("has-success has-error has-feedback")
                                .find(".form-control-feedback")
                                .remove();

                            changeStatus("success");
                            settings.onSuccess(data);

                            obj.find("input.reset").val("").trigger("change");
                            obj.find("textarea.reset").val("").trigger("change");
                            obj.find("select.reset option").prop("selected", false).trigger("change");
                            obj.find("select.reset[data-auto-complete] option").remove();
                            obj.find(".reset").first().focus();

                            if (settings.autoSave == false) {

                                obj.closest(settings.closeParent).hide();
                                obj.closest(".modal").modal('hide')

                                if (typeof data.message == "undefined") {

                                    console.log("Formulardaten gespeichert!");

                                    if (typeof data.redirect != "undefined") {
                                        if (settings.redirect == "?") {
                                            window.location.reload();
                                        } else {
                                            window.location = gentext(settings.redirect, settings.dataPost.data);
                                        }
                                    }

                                    /*

                                    Swal.fire({
                                        title: 'Bestätigt',
                                        html: data.message || "",
                                        type: 'success',
                                        timer: 2000,
                                        onClose: function () {
                                            if (typeof data.redirect != "undefined") {
                                                if (settings.redirect == "?") {
                                                    window.location.reload();
                                                } else {
                                                    window.location = gentext(settings.redirect, settings.dataPost.data);
                                                }
                                            }
                                        }
                                    });

                                    */

                                }
                            }
                        }

                        // if (settings.autoSave == true) {
                        // }

                    }
                });

            }

            // Bei Aktivität ändert ein Icon seine Klasse
            function changeStatus(status) {


                $("#" + icon).removeClass(settings.status.edit);
                $("#" + icon).removeClass(settings.status.save);
                $("#" + icon).removeClass(settings.status.success);
                $("#" + icon).removeClass(settings.status.error);
                $("#" + icon).addClass(settings.status[status]);

                //$("."+settings.status.error+"-hide").hide();
                //$("."+settings.status.error+"-show").hide();
                //$("."+settings.status.success+"-hide").hide();
                //$("."+settings.status.success+"-show").hide();
                $("." + settings.status[status] + "-show").show();
                $("." + settings.status[status] + "-hide").hide();

                if (status == 'edit' && settings.autoSave == true) {

                    //console.log(settings.autoSave);

                    getData();
                    postData();

                }

                settings.onChange(obj);

            }

            /* Ersetzt Platzhalter im Text */
            function gentext(html, options) {

                if (typeof html != 'undefined') {

                    $.each(options, function (key, value) {

                        var regExpKey = new RegExp("\\{[\\$]*" + key + "\\}", "gi");
                        html = html.replace(regExpKey, value);

                    });

                    return html;

                } else {

                    return '...';

                }

            }

            obj.find("input,textarea").keypress(function () {
                changeStatus("edit");
            });
            obj.find("input,textarea").change(function () {
                changeStatus("edit");
            });
            obj.find("select").change(function () {
                changeStatus("edit");
            });

            /*obj.find("button[type=submit]").click(function () {

                var data = $(this).data();

                if (typeof data.fncOverride != "undefined") {

                    settings.fncOverride = data.fncOverride;

                }

                if (typeof data.confirm != "undefined") {

                    Swal.fire({
                        title: 'Achtung',
                        html: data.confirm,
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Bestätigen',
                        cancelButtonText: 'Abbrechen',
                    }).then(function () {
                        getData(data.action);
                        postData();
                    });

                    return false;

                } else {

                    getData(data.action);

                    postData();

                }
                
            });*/

            if (obj.prop("tagName").toLowerCase() != "form") {

                if (settings.primaryButton == null) {

                    obj.find("button:not(.no-submit), .submit").click(function () {

                        console.log($(this).data());

                        var data = $(this).data();

                        if (typeof data.fncOverride != "undefined") {

                            settings.fncOverride = data.fncOverride;

                        }

                        if (typeof data.confirm != "undefined") {

                            Swal.fire({
                                title: 'Achtung',
                                html: data.confirm,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Bestätigen',
                                cancelButtonText: 'Abbrechen',
                            }).then(function (result) {
                                if (result.isConfirmed) {
                                    getData(data.action);
                                    postData();
                                }
                            });

                        } else {

                            getData(data.action);
                            postData();

                        }

                    });

                } else {

                    obj.find(settings.primaryButton).click(function () {
                        getData();
                        postData();
                        if (typeof $(this).attr("url") != "undefined") {
                            window.location = $(this).attr("url");
                        }
                    });

                }

            } else {

                if (settings.primaryButton == null) {

                    obj.submit(function () {

                        var data = $(this).data();

                        // alert("submit (form)");

                        if (typeof data.fncOverride != "undefined") {

                            settings.fncOverride = data.fncOverride;

                        }

                        if (typeof data.confirm != "undefined") {

                            Swal.fire({
                                title: 'Achtung',
                                html: data.confirm,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Bestätigen',
                                cancelButtonText: 'Abbrechen',
                            }).then(function (result) {
                                if (result.isConfirmed) {
                                    getData(data.action);
                                    postData();
                                }
                            });

                        } else {

                            getData(data.action);

                            postData();

                        }

                        return false;

                    });

                } else {

                    obj.submit(function () {
                        getData();
                        postData();
                        if (typeof $(this).attr("url") != "undefined") {
                            window.location = $(this).attr("url");
                        }

                        return false;
                    });

                }

            }

            settings.onChange(obj);

            count++;

        });

    }

}(jQuery));
