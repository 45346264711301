(function ($) {
    $.widget("ui.apiListing", {
        version: "1",
        options: {
            module: '',
            action: '',
            controller: 'Listing',
            formController: 'Form',
            type: 'table',
            colCount: 0,
            activeCol: 0,
            skin: '',
            interval: 60,
            id: '',
            pushUrl: '',
            pushObj: {},
            push: 0,
            verbose: false,
            mysql: {
                sort: '',
                direction: 'DESC',
                results: 10,
                page: 1,
            },
            table: {
                page: 1,
                pages: 1,
                results: 0,
                cols: 0,
                rows: 0,
                activeCol: 0,
                activeRow: 999,
            },
            header: null,
            info: null,
            content: null,
            columns: null,
            hideColumns: [],
            rememberFilter: {},
            onUpdate: function () {
            },
            template: {
                empty: null,
                header: null,
                body: null,
                footer: null,
                navi: null,
                snavi: null,
                infotext: null,
                title: null
            }
        },
        bodyFormData: {},
        _destroy: function () {
            var that = this;
            this._request = function (x) {
                /*that.element.remove();*/
                that.options = null;
            };
        },
        _create: function () {
            var that = this;
            if (typeof $(window).data('apiListings') == 'undefined') {
                $(window).data('apiListings', 1);
            } else {
                $(window).data('apiListings', $(window).data('apiListings') + 1);
            }
            var $_GET = {};
            if (document.location.toString().indexOf('?') !== -1) {
                var query = document.location
                    .toString()
                    .replace(/^.*?\?/, '')
                    .replace(/#.*$/, '')
                    .split('&');
                for (var i = 0, l = query.length; i < l; i++) {
                    var aux = decodeURIComponent(query[i]).split('=');
                    $_GET[aux[0]] = aux[1];
                }
            }
            // If page is parameter in URL, jump to page
            if (typeof $_GET['listing-page'] != 'undefined')
                this.options.mysql.page = $_GET['listing-page'];
            if (typeof this.element.data('interval') != 'undefined')
                this.options.interval = this.element.data('interval');
            if (that.options.verbose) {
                console.log("ajaxlister > _create() 'this.options'", this.options);
            }

            this.autoColSpan();

        },
        _getForm: function (selector) {
            var that = this;
            var data = {};
            if (that.options.verbose) {
                console.log("ajaxlister > _getForm() 'this.element'", this.element);
            }
            $.each(this.element.find(selector).find("select,textarea,input"), function (key, value) {
                var name = this.name.replace(/\[.*]/, ''); // Listing filter can't cope with array input names
                var value = $(this).val();
                if (name) {
                    // If input is a datepicker/datetimepicker, then get the DateTime format as value
                    if (typeof $(this).data("DateTimePicker") != 'undefined' && $(this).data("DateTimePicker").date() != null) {
                        if ($(this).hasClass("datepicker")) {
                            value = $(this).data("DateTimePicker").date().format("YYYY-MM-DD");
                        } else if ($(this).hasClass("timepicker")) {
                            value = $(this).data("DateTimePicker").date().format("hh:mm:ss");
                        } else {
                            value = $(this).data("DateTimePicker").date().format("YYYY-MM-DD hh:mm:ss");
                        }
                    }
                    if (value && value != '' && value != []) {
                        if (($(this).is("[type=checkbox],[type=radio]") && $(this).prop("checked")) || !$(this).is("[type=checkbox],[type=radio]")) {
                            if (typeof data[name] == 'undefined') {
                                data[name] = value;
                            } else {
                                if (typeof data[name] == 'string') {
                                    data[name] = [data[name]];
                                }
                                if (value.constructor === Array) {
                                    data[name] = data[name].concat(value);
                                } else {
                                    data[name].push(value);
                                }
                            }
                        }
                    }
                }
            });
            if (that.options.verbose) {
                console.log("ajaxlister > _getForm() 'filter'", data);
            }
            return data;
        },
        _getFormQs: function (selector) {
            //console.log(selector, this.element.find(selector), this.element.find(selector).find("input,select,textarea"), this.element.find(selector).find("input,select,textarea").serialize());
            return this.element.find(selector).find("input,select,textarea").serialize();
        },
        _exportCSV: function (exportData) {
            var that = this;
            //that.options.mysql.cols;
            var data, filename, link;
            that._convertArrayToCSV({
                data: exportData,
                keys: that.options.mysql.cols,
                filename: that.options.action + '_' + Date.now() + '.csv',
            });
            that.element.find('.listing-loader').removeClass('show').hide();
        },
        _convertArrayToCSV: function (args) {
            var that = this;
            if (that.options.verbose) {
                console.log("ajaxlister > _convertArrayToCSV() 'args'", args);
            }
            var result, ctr, keys, columnDelimiter, lineDelimiter, data;
            data = args.data || null;
            if (data == null || !data.length) {
                return null;
            }
            columnDelimiter = args.columnDelimiter || ';';
            lineDelimiter = args.lineDelimiter || '\n';
            if (that.options.columns != null) {
                result = '';
                result += that.options.columns.join(columnDelimiter);
                result += lineDelimiter;
                if (that.options.verbose) {
                    console.log("ajaxlister > _convertArrayToCSV() 'result'", result);
                }
                if (that.options.verbose) {
                    console.log("ajaxlister > _convertArrayToCSV() 'data'", data);
                }
                data.forEach(function (item) {
                    ctr = 0;
                    that.options.columns.forEach(function (key) {
                        if (ctr > 0) result += columnDelimiter;
                        item[ctr] = item[ctr].split(';').join('');
                        result += item[ctr] || '';
                        ctr++;
                    });
                    result += lineDelimiter;
                });
            } else {
                keys = args.keys || Object.keys(data[0]);
                if (that.options.verbose) {
                    console.log("ajaxlister > _convertArrayToCSV() 'keys'", keys);
                }
                result = '';
                result += keys.join(columnDelimiter);
                result += lineDelimiter;
                data.forEach(function (item) {
                    ctr = 0;
                    keys.forEach(function (key) {
                        if (ctr > 0) result += columnDelimiter;
                        result += item[key] || '';
                        ctr++;
                    });
                    result += lineDelimiter;
                });
            }
            var filename = args.filename || 'export.csv';
            let blob = new Blob(["\uFEFF"+result], {type: "text/csv;charset=utf-8;"});
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename)
            } else {
                let link = document.createElement("a");
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
        _request: function (request, repeat, fromInterval) {
            var that = this;
            that.element.find('.listing-area').hide();
            that.element.find('.listing-loader').addClass('show').show();

            // init options as empty object for following checks
            that.options = that.options || {}

            if (!that.options.action) {
                return;
            }

            // remember current filter settings before submitting the request
            // that._rememberFilter();
            // console.log(that.options.mysql);
            // If _request was called by interval and there is a focussed form element in the list
            // Don't update and just call the interval again
            if (fromInterval === true && that.element.find('.body form:focus, .body input:focus, .body textarea:focus, .body select:focus, .body .prevent-parent-list-update').length) {
                if (typeof repeat != 'undefined' && that.options.interval != 0) {
                    setTimeout(function () {
                        that._request(['info', 'body', 'header'], true, true);
                    }, that.options.interval * 1000);
                }
            } else {
                erwingoApp.ajax({
                    data: {
                        request: request,
                        module: that.options.module,
                        cl: that.options.controller,
                        action: that.options.action,
                        mysql: that.options.mysql,
                        filter: that._getForm('.filter'),
                        qsFilter: that._getFormQs('.filter-qs'),
                        colFilter: that._getForm('.col-filter'),
                        auto: fromInterval,
                    },
                    success: function (data) {
                        // initialize data as empty object if not present
                        if (typeof data === 'undefined' || !data || data === null) {
                            data = {};
                        }
                        /*
                        if (typeof data.content !== 'undefined' && !data.content.length && that.options.mysql.page != 1) {
                            that.options.mysql.page = 1;
                            if($(window).data('apiListings') == 1) {
                                updateUrlQueryParam("listing-page", that.options.mysql.page);
                            }
                            that._request(['info', 'body']);
                            return;
                        }
                        */
                        if ((typeof data.content !== 'undefined') && data.content != null
                            && (typeof that.options.mysql.page !== 'undefined' && that.options.mysql.page !== 1)) {
                            if (!data.content.length) {
                                if(that.options.mysql.page === undefined || that.options.mysql.page === null) {
                                    that.options.mysql.page = 1;
                                }
                                if ($(window).data('apiListings') === 1) {
                                    updateUrlQueryParam("listing-page", that.options.mysql.page);
                                }
                                that._request(['info', 'body']);
                                return;
                            }
                        }
                        if (typeof data.info !== 'undefined') {
                            that.options = $.extend(that.options, data.info);
                        }
                        if (typeof data.header !== 'undefined') {
                            that._buildHeader(data.header);
                        }
                        if (typeof data.columns !== 'undefined') {
                            that.options.columns = data.columns;
                        }
                        if (typeof data.export !== 'undefined') {
                            that._exportCSV(data.export);
                            return;
                        }
                        // hideColumns does not get persisted on backend so no need to override frontend settings here
                        // if (typeof data.hideColumns !== 'undefined') {
                        //     that.options.hideColumns = data.hideColumns;
                        // }
                        that._buildPagination();
                        if (typeof data.content !== 'undefined') {
                            that.element.find(".empty").hide();
                            that._buildBody(data.content);
                        } else {
                            that.element.find(".empty").show();
                        }
                        if (typeof data.function !== 'undefined') {
                            if (that.options.verbose) {
                                console.log("ajaxlister > _request() 'data.function'", data.function);
                            }
                            if (typeof data.params == 'undefined') {
                                window[data.function]();
                            } else {
                                if (that.options.verbose) {
                                    console.log("ajaxlister > _request() 'data.params'", data.params);
                                }
                                window[data.function](data.params.bonus_user_year_count, data.params.bonus_user_year_percentage_sum);
                            }
                        }
                        that._buildHeader();
                        that._updateHeader();
                        that.updateCellWidths();
                        if (typeof repeat != 'undefined') {
                            // Wiederhole Vorgang wenn interval != 0
                            if (that.options.interval != 0) {
                                setTimeout(function () {
                                    that._request(['info', 'body', 'header'], true, true);
                                }, that.options.interval * 1000);
                            }
                        }
                        that.options.onUpdate(that.element);
                        that._toggleColumns();

                        if(that.element.find('input[name="selection"]:checked').val() === 'all') {
                            var $table = that.element.find(".body").closest("table");
                            $table.find("tr .listactions-check").prop("checked", true);//.trigger("change");

                            let checker1 = $(".listactions-checkall");
                            if(checker1.length > 0) {
                                checker1.prop("indeterminate", false);
                                checker1.prop("checked", false);
                            }
                        }

                        if(that.element.find('input[name="selection"]:checked').val() === 'all') {
                            var $table = that.element.find(".body").closest("table");
                            $table.find("tr .listactions-check").prop("checked", true);//.trigger("change");

                            let checker1 = $(".listactions-checkall");
                            if(checker1.length > 0) {
                                checker1.prop("indeterminate", false);
                                checker1.prop("checked", false);
                            }
                        }
                        if(that.element.find('input[name="page_selection"]:checked').val() === 'page') {
                            var $table = that.element.find(".body").closest("table");
                            $table.find("tr .listactions-check").prop("checked", true);//.trigger("change");
                        }
                    }
                });

                that.element.find('.body').fadeIn();
             }
            // Show search reset button
            if (that.element.find('[name="mysql-search"]').val()) {
                that.element.find('.reset-search').show();
            } else {
                that.element.find('.reset-search').hide();
            }
        },
        _resetColFilter: function () {
            var that = this;
            that.element.find(".col-filter input:not([type=checkbox])").each(function () {
                $(this).val("");
            });
        },
        _toggleColumns: function () {
            var that = this;
            if (that.options.hideColumns != null) {
                that.options.hideColumns.forEach(function (key) {
                    var idx = that.element.find('.header [data-col-toggle="' + key + '"]').first().index();
                    var $col = that.element.find("tr th:nth-child(" + (idx + 1) + "), tr td:nth-child(" + (idx + 1) + ")");
                    $col.addClass("d-none");
                });
            }
            that.element.find(".col-toggle input[type=checkbox]").each(function () {
                var name = $(this).attr("name");
                var idx = that.element.find('.header [data-col-toggle="' + name + '"]').first().index();
                var $col = that.element.find("tr th:nth-child(" + (idx + 1) + "), tr td:nth-child(" + (idx + 1) + ")");
                if ($(this).is(":checked")) {
                    $col.removeClass("d-none");
                    var indx = that.options.hideColumns.indexOf(name)
                    if (indx > -1) {
                        that.options.hideColumns.splice(indx, 1)
                    }
                } else {
                    $col.addClass("d-none");
                    // only push hidden column name into array if not present
                    if (that.options.hideColumns.indexOf(name) <= -1) {
                        that.options.hideColumns.push(name)
                    }
                }
            });

            this.autoColSpan();
        },
        _rememberFilter: function () {
            var that = this;
            if (typeof that.options.rememberFilter[that.options.apiListing] == 'undefined') {
                that.options.rememberFilter[that.options.apiListing] = {};
            }

            this.element.find('.filter :input, .col-filter :input, .col-toggle :input').not('[data-filter-nosave="true"]').each(function () {

                // for select2 options we have to fetch id and name in order to be able to restore the selection properly
                if (this.dataset && 'autoComplete' in this.dataset) {
                    that.options.rememberFilter[that.options.apiListing][this.name] = $(this).select2('data').map(opt => {
                        return {id: opt.id, name: opt.text}
                    })
                } else {
                    if (!$(this).is('[type=checkbox],[type=radio]') || this.checked) {
                        that.options.rememberFilter[that.options.apiListing][this.name] = $(this).val();
                    }
                }
            });

            that.options.rememberFilter[that.options.apiListing]['hideColumns'] = that.options.hideColumns

            // Merge filter options into localStorage item and persist it to local storage
            var filterOptions = {}
            if (window.localStorage.getItem("erwingoRememberFilter")) {
                filterOptions = JSON.parse(window.localStorage.getItem("erwingoRememberFilter"));
            }
            $.extend(filterOptions, that.options.rememberFilter);
            window.localStorage.setItem("erwingoRememberFilter", JSON.stringify(filterOptions));
        },
        _restoreFilter: function () {
            var that = this;
            // try to read persisted options from localStorage item
            try {
                var filterOptions = JSON.parse(window.localStorage.getItem("erwingoRememberFilter"));
                filterOptions = filterOptions[that.options.apiListing];
                if (!filterOptions) {
                    throw 'No filter for this listing remembered';
                }
            } catch (error) {
                return;
            }
            that.options.rememberFilter = filterOptions
            that.options.hideColumns = filterOptions['hideColumns']
            delete filterOptions['hideColumns']
            // restore filters
            $.each(filterOptions, function (index, value) {
                /**
                 *   Notice MB: der Selektor mit id$='search'] funktioniert nicht. Ich vermute ein Überbleibsel einer früheren DOM-Version?
                 */
                    // that.element.find("[id$='search'] [name='" + index + "']").val(value).trigger('change.select2');

                var _elements = that.element.find(".filter [name='" + index + "']:visible, .col-filter [name='" + index + "']:visible")
                        .not('[data-filter-nosave="true"]');

                // we have to treat async select boxes differently here
                // to set the val() of an async select won't work since the options may not be here at this moment
                // so we have to create a seemingly new option and add it to the select until it fetches it's options
                if (typeof value == 'object' && _elements.length == 1 && _elements[0].dataset && 'autoComplete' in _elements[0].dataset) {
                    // Create a DOM Option and pre-select by default for every selected element
                    value.forEach(opt => {
                        var newOption = new Option(opt.name, opt.id, false, true);
                        _elements.append(newOption).trigger('change.select2');
                    })
                } else {
                    _elements.each(function () {
                        if (!$(this).is('[type=checkbox],[type=radio]')) {
                            _elements.val(value).trigger('change');
                        } else if ($(this).val() == value) {
                            $(this).prop('checked', true).trigger('change');
                        }
                    })
                }
            });
            // Open search area in backend windows
            if (this.element.find('.filter [name=mysql-search]').val()) {
                let $btn = this.element.closest('.window').find('.slideout-toggle[href$="search"]');
                if ($btn && !$btn.hasClass('clicked')) {
                    setTimeout(function() {
                        $btn.trigger('click')
                    }, 500);
                }
            }
            // restore hidden columns
            $.each(that.options.hideColumns, function (index, value) {
                that.element.find(".col-toggle input[type=checkbox][name='" + value + "']").prop('checked', false).trigger('change')
            })
            // set number of results from input again
            if (that.element.find('.filter [name="mysql-results"]').length) {
                this.options.mysql.results = that.element.find('.filter [name="mysql-results"]').val();
            }
            that.update();
        },
        _init: function () {
            var that = this;
            that.options = $.extend(true, that.options, that.element.data()); // Hole Einstellungen aus Data Attributen
            //console.log(that.options);
            that._restoreFilter();
            // Neu: data-api-listing="[action]/[sort field]/[sort order]"
            if (typeof that.options.apiListing != 'undefined') {
                var res = that.options.apiListing.split("/");
                that.options.action = res[0];
                that.options.mysql.sort = res[1];
                that.options.mysql.direction = res[2];
            }
            // Übernehme Ergebnisse
            if (typeof that.options.results != 'undefined') {
                that.options.mysql.results = that.options.results;
            }
            // Ergebnismenge anhand der Widgetgröße bestimmen
            if (typeof that.options.autoSize != 'undefined') {
                var maxHeight = that.element.find('.widget-body').height();
                var tbody = that.element.find('tbody').height();
                var thead = that.element.find('thead').height();
                var results = Math.floor((maxHeight - thead) / tbody);
                that.options.mysql.results = results;
            }
            // Übernehme Sortierung
            if (typeof that.options.order != 'undefined') {
                var res = that.options.order.split(" ");
                that.options.mysql.sort = res[0];
                that.options.mysql.direction = res[1];
            }
            // Zähle Spalten bei Tabellen
            that.options.colCount = that.element.find(".body tr").children().length;
            count = $("#selected ul").children().length;
            // Durchsuche Templates
            $.each(that.options.template, function (key, value) {
                that.options.template[key] = that.element.find("." + key).html(); // Speicher Template
                that.element.find("." + key).empty(); // Leere Template
            });
            that._request(['info', 'body', 'header'], true);
            // Input (Werden durch apiForm und apiRequest abgelöst!)
            that.element.on("click", "[data-controller], [data-api-form] .btn", function () {
                setTimeout(function () {
                    that._rememberFilter()
                    that._request(['info', 'body', 'header']);
                }, 500);
            });
            // If reset-button clicked, restore fields
            that.element.on("click", "[type=reset]", function () {
                that._restoreFilter();
            });
            // Filter
            that.element.find(".filter input").keypress(function (e) {
                if (e.which == 13) {
                    that._rememberFilter()
                    that.options.mysql.page = 1;
                    that._request(['info', 'body']);
                }
            });
            that.element.find(".col-filter input").keypress(function (e) {
                if (e.which == 13) {
                    that._rememberFilter()
                    that.options.mysql.page = 1;
                    that._request(['info', 'body']);
                }
            });
            that.element.find('.col-filter input:not([type="number"])').dblclick(function (e) {
                that._resetColFilter();
            });
            that.element.find('.col-filter select').change(function () {
                that._rememberFilter()
                that._request(['info', 'body']);
            });
            that.element.find(".filter input[type=checkbox], .filter input[type=radio], .col-filter input[type=checkbox], .col-filter input[type=radio]").change(function () {
                that._rememberFilter()
                that.options.mysql.page = 1;
                that._request(['info', 'body']);
            });
            that.element.find('.filter select[name="mysql-results"],.filter input[name="mysql-results"]').change(function () {
                that._rememberFilter()
                that.options.mysql.page = 1;
                that.options.mysql.results = $(this).val();
                that._request(['info', 'body']);
            });
            that.element.find('.filter select[name="mysql-pages"]').change(function () {
                that._rememberFilter()
                that.options.mysql.page = $(this).val();
                that._request(['body']);
            });
            that.element.find('.filter [name="mysql-sort"]').change(function () {
                that._rememberFilter()
                that.options.mysql.sort = $(this).val();
                that._request(['body']);
            });
            that.element.find('.filter select[name="mysql-order"]').change(function () {
                var res = $(this).val().split(" ");
                that._rememberFilter()
                that.options.mysql.sort = res[0];
                that.options.mysql.direction = res[1];
                that.options.mysql.page = 1;
                that._request(['info', 'body']);
            });
            that.element.find('.filter select').change(function () {
                that._rememberFilter()
                that._request(['info', 'body']);
            });
            that.element.find('.filter-qs select, .filter-qs input').change(function () {
                that._rememberFilter()
                that._request(['info', 'body']);
            });
            that.element.find('[data-export]').click(function () {
                var res = $(this).data('export').split(",");
                that.options.mysql.cols = res;
                that._request(['export']);
            });
            that.element.find(".col-toggle input[type=checkbox]").change(function () {
                that._toggleColumns();
                that._rememberFilter()
            });
            that.element.find(".body").on("change", "input[type=checkbox]", function () {
                var name = $(this).attr('name');
                if (typeof that.bodyFormData[name] == 'undefined') {
                    that.bodyFormData[name] = [];
                }
                that.element.find(".body input[type=checkbox][name='" + name + "']").each(function () {
                    var value = $(this).attr('value');
                    if ($(this).prop("checked")) {
                        if (that.bodyFormData[name].indexOf(value) < 0) {
                            that.bodyFormData[name].push(value);
                        }
                    } else {
                        var index = that.bodyFormData[name].indexOf(value);
                        if (index > -1) {
                            that.bodyFormData[name].splice(index, 1);
                        }
                    }
                });
                if (that.options.verbose) {
                    console.log("ajaxlister > _init() 'that.bodyFormData'", that.bodyFormData);
                }
            });
            // Header
            that.element.find(".header").on("click", "a", function () {
                if ($(this).data("col") != '') {
                    that.options.mysql.sort = $(this).data("col");
                    that.options.table.activeRow = $(this).data("col");
                    that.options.mysql.page = 1;
                    if (that.options.mysql.direction == "ASC") {
                        that.options.mysql.direction = "DESC";
                    } else {
                        that.options.mysql.direction = "ASC";
                    }
                    that._request(['body']);
                }
            });
            // Pagination
            that.element.find(".navi").on("click", "li:not(.disabled) a:not(.disabled)", function () {
                var page = $(this).data("value")
                if (page < 0) {
                    return;
                }
                that.options.mysql.page = page;
                // Add current page to URL (for back button functionality; only if there is only one listing on page)
                if ($(window).data('apiListings') == 1) {
                    updateUrlQueryParam("listing-page", that.options.mysql.page);
                }
                that._request(['info', 'body']);
            });
            that.element.find('.reset-search').on('click', function () {
                that.element.find('.filter [name=mysql-search]').val('');
                that._rememberFilter();
                that._request(['info', 'body']);
            });
            $(window).resize(that.updateCellWidths);
        },
        _buildBody: function (data) {
            //alert("body");
            var that = this;
            var html = '';
            $.each(data, function (key, value) {
                html += that._gentext(that.options.template.body, value);
            });
            if (html == '' && typeof that.options.template.empty != 'undefined') {
                if (that.options.template.empty) {
                    html = '<tr><td colspan="' + that.options.colCount + '" class="no-results">' + that.options.template.empty + '</td></tr>'; // Empty-Text
                }
                that.element.find('.navi').empty();
            } else if (html == '' && that.options.type == 'table') {
                html = '<tr><td colspan="' + that.options.colCount + '" class="no-results"><div class="text-center text-muted">-- '+i18next.t('No Results Found')+' --</div></td></tr>';
                that.element.find('.navi').empty();
            }
            //remove popover
            $(document).find('.popover').remove();
            that.element.find('.body').html(html);
            // Remove Loader / Spinner
            that.element.find('.listing-loader').removeClass('show').hide();
            that.element.find('.listing-area').show();
            // Check/Uncheck Checkboxes
            $.each(that.bodyFormData, function (name, values) {

                that.element.find('.body input[type=checkbox][name="' + name + '"]')
                    .not('[data-no-autocheck="true"]').each(function () {

                    let value = $(this).attr('value');
                    /* Normale checkboxen mit 1,0,on,off ignorieren */
                    if (value && value.length > 3) {
                        if (values.indexOf(value) > -1) {
                            $(this).prop("checked", true);
                        } else {
                            $(this).prop("checked", false);
                        }
                    }
                });
            });
        },
        _buildHeader: function (data) {
            //alert("header");
            var that = this;
            that.element.find('.header').html(that.options.template.header);
            // Wird nicht mehr benötigt
        },
        _updateHeader: function () {
            var that = this;
            //that._resetColFilter();
            that.element.find(".header th i.fa").remove();
            that.element.find(".header th a").append("<i></i>");
            that.element.find(".header th i").addClass("fa");
            that.element.find(".header th").removeClass('active');
            that.element.find(".col-filter th").removeClass('active');
            that.element.find(".body td").removeClass('active');
            var activeCol = 0;
            that.element.find(".header th").each(function () {
                if ($(this).find('a').data('col') == that.options.mysql.sort) {
                    $(this).addClass('active');
                    that.element.find(".body tr").find('td:eq(' + activeCol + ')').addClass("active");
                    that.element.find(".col-filter").find('th:eq(' + activeCol + ')').addClass("active");
                    if (that.options.mysql.direction == "ASC") {
                        $(this).find("i").addClass("fa-sort-asc");
                    } else {
                        $(this).find("i").addClass("fa-sort-desc");
                    }
                }
                activeCol++;
            });
        },
        _gentext: function (html, options) {
            if (typeof html != 'undefined') {
                html = html.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
                $.each(options, function (key, value) {
                    var regExpKey = new RegExp("\\@" + key + "\\@", "gi");
                    html = html.replace(regExpKey, (value != null ? value : ""));
                });
                $.each(options, function (key, value) {
                    /* Can be done with one RegEx...
                     var regExpKey = new RegExp("\\{[\\$]*" + key + "\\}", "gi");
                     html = html.replace(regExpKey, (value != null ? value : ""));
                     var regExpKey = new RegExp("\\$" + key, "gi");
                     html = html.replace(regExpKey, (value != null ? value : ""));*/
                    /* RegEx explanation:
                     * Match either of these:
                     * 1. "{$"+key+"}"
                     * 2. "$"+key+"[^a-z0-9_]"
                     *    Only if next character after "key" is not alphanumeric or "_".
                     *    Otherwise "foo" would match "foobar" or "foo_bar" and break the space time continuum
                     *
                     * Replace explanation:
                     * "$1" is the "[^a-z0-9_]" part and has to be excluded from the replace.
                     * This is only relevant if no {} is used.
                     */
                    var regExpKey = new RegExp("(?:\\{\\$" + key + "\\}|\\$" + key + "([^a-z0-9_]))", "gi");
                    html = html.replace(regExpKey, (value != null ? value + "$1" : "$1"));
                });
                var re = /<%(([^>]|[^%]>)+)?%>/g, reExp = /(^( )?(if|for|else|switch|case|break|{|}))(.*)?/g,
                    code = 'var r=[];\n', cursor = 0;
                var add = function (line, js) {
                    js ? (code += line.match(reExp) ? line + '\n' : 'r.push(' + line + ');\n') :
                        (code += line != '' ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n' : '');
                    return add;
                }
                while (match = re.exec(html)) {
                    add(html.slice(cursor, match.index))(match[1], true);
                    cursor = match.index + match[0].length;
                }
                add(html.substr(cursor, html.length - cursor));
                code += 'return r.join("");';
                return new Function(code.replace(/[\r\t\n]/g, '')).apply(options);
            } else {
                return '...';
            }
        },
        _buildPagination: function () {
            var that = this;
            var results = that.options.table.results;
            var page = that.options.mysql.page;
            var pResults = that.options.mysql.results;
            var to = pResults * page;
            if (to > results) {
                to = results;
            }
            var from = pResults * (page - 1) + 1;
            if (from < 0 || results <= 0) {
                from = 0;
            }
            if (from - to === 1) {
                from = 1;
                to = pResults
            }
            that.element.find(".infotext").text(that._gentext(that.options.template.infotext, {
                results: results,
                from: from,
                to: to
            }));
            var first = '';
            var last = '';
            var first_page = '';
            var last_page = '';
            var atn = [];
            var html = '';
            var shtml = '';
            var links = that.options.mysql.page;
            var rechts = that.options.mysql.page;
            var maxviewpages = 5;

            for (var i = 1; i < maxviewpages; i = i + z) {
                z = 0;
                links--;
                rechts++;
                if (links > 0) {
                    atn[links] = '<li class="page-item"><a class="page-link" data-value="' + links + '" href="javascript:">' + links + '</a></li>';
                    z++;
                }
                if (rechts <= that.options.table.pages) {
                    atn[rechts] = '<li class="page-item"><a class="page-link" data-value="' + rechts + '" href="javascript:">' + rechts + '</a></li>';
                    z++;
                }
                if (z == 0) {
                    z++;
                }
            }

            // Dirty fix for problem when shown pages is an even number and current page is bigger than half of shown pages
            if (maxviewpages % 2 == 0 && that.options.mysql.page > maxviewpages / 2) {
                atn.pop();
            }

            atn[that.options.mysql.page] = '<li class="page-item active current" aria-current="page"><a class="page-link" data-value="' + that.options.mysql.page + '" href="javascript:">' + that.options.mysql.page + '</a></li>';
            first_page = that.options.mysql.page;
            last_page = that.options.mysql.page;
            first_page--;
            last_page++;
            var styleClass = "page-item";

            if (first_page < 1) {
                first_page = 1;
            }

            if (last_page > that.options.table.pages) {
                last_page = that.options.table.pages;
            }

            if (that.options.mysql.page == 1) {
                styleClass += " disabled";
            }

            html = html + '<li class="' + styleClass + '"><a class="page-link" data-value="1" href="javascript:"><i class="fa fa-angle-double-left"></i></a></li>';
            html = html + '<li class="' + styleClass + '"><a class="page-link" data-value="' + Math.max(that.options.mysql.page - 1, 1) + '" href="javascript:"><small><i class="fa fa-chevron-left"></i></small></a></li>';

            styleClass = "page-item";

            if (that.options.mysql.page === first_page) {
                styleClass += " disabled";
            }

            for (var i = 0; i <= that.options.table.pages; i++) {
                if (typeof atn[i] != 'undefined') {
                    html = html + atn[i];
                }
            }

            if (that.options.mysql.page === last_page || last_page === 0) {
                styleClass += " disabled";
            } else {
                styleClass = '';
            }

            if (that.options.mysql.page === that.options.table.pages || last_page === 0) {
                styleClass += " disabled";
            } else {
                styleClass = '';
            }

            html = html + '<li class="' + styleClass + '"><a class="page-link" data-value="' + Math.min(that.options.mysql.page + 1, that.options.table.pages) + '" href="#' + that.options.id + '"><small><i class="fa fa-chevron-right"></i></small></a></li>';
            html = html + '<li class="' + styleClass + '"><a class="page-link" data-value="' + that.options.table.pages + '" href="#' + that.options.id + '"><i class="fa fa-angle-double-right"></i></a></li>';

            that.element.find(".navi").html(html);

            for (page = 1; page <= that.options.table.pages; page++) {
                shtml += that._gentext(that.options.template.snavi, {
                    page: page,
                    pages: that.options.table.pages,
                    selected: (that.options.mysql.page == page ? 'selected' : ''),
                });
            }

            that.element.find(".snavi").html(shtml);
            erwingoApp.calcHeight();
        },
        updateCellWidths: function () {
            var that = this;
            if (typeof that.element == 'undefined') return;
            var $table = that.element.find(".body").closest("table");
            if ($table.hasClass("column-ratio-fixed")) {
                // Reset th + td widths
                $table.find("td,th").css({maxWidth: "", width: ""});
                var distribution = [],
                    distributableWidth = 0,
                    fixedWidth = 0,
                    $firstTr = $table.find("tr").first();
                $firstTr.find("td,th").each(function () {
                    if ($(this).hasClass("fix")) {
                        fixedWidth += $(this).outerWidth();
                    } else {
                        distributableWidth += $(this).outerWidth();
                    }
                });
                $firstTr.find("td:not(.fix),th:not(.fix)").each(function () {
                    distribution.push($(this).outerWidth() / distributableWidth);
                });
                var parentWidth = $table.parent().width(),
                    distributableParentWidth = parentWidth - fixedWidth;
                $table.find("tr").each(function () {
                    $(this).find("td:not(.fix),th:not(.fix)").each(function (index) {
                        $(this).css({maxWidth: distributableParentWidth * distribution[index]});
                    });
                });
            }
        },

        autoColSpan: function () {
            let visibleColumns = this.element.find('thead tr').first().find('> td:visible, > th:visible').length;
            this.element.find('td.listactions').attr('colspan', visibleColumns);
            const el1 = document.querySelector('div[data-api-listing="event_training_topics/training_topic_title/ASC"]');
            if (el1 && visibleColumns === 0) {
                visibleColumns = 3;
            }
            this.element.find('td.no-results').attr('colspan', visibleColumns);
        },

        update: function () {
            // console.log("called apiListing update()");
            this._request(['info', 'body', 'header']);
        }
    });

    function updateUrlQueryParam(key, value) {
        var uri = window.location.href;
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            uri = uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            uri = uri + separator + key + "=" + value;
        }
        var stateObj = {};
        stateObj[key] = value;
        history.pushState(stateObj, "", uri);
    }
})(jQuery);
