(function ($) {

    $.fn.extend({

        fileUpload: function (options) {

            console.log("init upload...");

            var defaults = {
                form: this.closest("form"),
                actionURL: null,
                success: function () {
                },
                error: function () {
                    alert(i18next.t('An error occurred while uploading the file.'));
                },
            };

            var options = $.extend(defaults, options);

            console.log(options);

            return this.each(function () {

                $('<iframe />', {
                    id: 'upload_iframe',
                    name: 'upload_iframe',
                    style: 'width:0; height:0; border:none;'
                }).appendTo('body');

                var form = $(options.form);

                form.attr("action", options.actionURL);
                //form.attr("action", "http://google.de/");
                form.attr("method", "POST");
                form.attr("enctype", "multipart/form-data");
                form.attr("encoding", "multipart/form-data");
                form.attr("target", "upload_iframe");

                form.trigger('submit');

                console.log("form trigger...");

                $("#upload_iframe").load(function () {

                    console.log("start upload...");

                    //var json = document.getElementById('upload_iframe').contentWindow.document.body.innerText;
                    //var json = document.getElementById('upload_iframe').contentWindow.document.body.innerHTML;

                    //var json = $("iframe#upload_iframe").contents();

                    var json = $("#upload_iframe").contents().text();

                    console.log(json);

                    json != '' ? options.success(json) : options.error();

                    $("iframe#upload_iframe").remove();

                    console.log("end upload...");

                });

            });
        }

    });

})(jQuery);
