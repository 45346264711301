/*

 * Description: jQuery-Plugin zum Steuern von Formular-Aktionen (z.B. Ausblenden eines Feldes, wenn eine Checkbox geklickt wird)
 */

(function ($) {

    $.fn.formwatcher = function (call) {

        var call = call || "init";

        /* Action functions */

        this.fwActionShow = function ($elem) {
            $elem.show();

            forElemAndChildren($elem, function () {
                var required = $(this).data("fw-required");
                if (required == 1) $(this).prop("required", true);
            });
        }

        this.fwActionHide = function ($elem) {
            $elem.hide();

            forElemAndChildren($elem, function () {
                var required = $(this).prop("required") || false;
                if (required) $(this).attr("data-fw-required", 1).prop("required", false);
            });
        }

        this.fwActionEmpty = function ($elem) {
            // Empty textual inputs and uncheck checkboxes ($elem & child inputs of $elem)
            forElemAndChildren($elem, function () {
                if ($(this).is("[type=checkbox],[type=radio]")) {
                    $(this).prop("checked", false).trigger("change");
                } else if ($(this).is("select")) {
                    if ($(this)[0].hasAttribute('data-auto-complete')) {
                        $(this).find("option").remove()
                        $(this).trigger("change");
                    } else if ($(this).prop("multiple")) {
                        $(this).find("option").prop("selected", false).trigger("change");
                    } else {
                        // console.log('search empty option', $(this).find("option[value='']"));
                        $(this).find("option:first-child").prop("selected", true);
                        $(this).find("option[value='']").prop("selected", true);
                        $(this).trigger('change');
                    }
                } else {
                    $(this).val("");
                }
            });
        }

        this.fwActionDisable = function ($elem) {
            // console.log("fwActionDisable");
            forElemAndChildren($elem, function () {
                var required = $(this).prop("required") || false;
                if (required) $(this).attr("data-fw-required", 1).prop("required", false);
                $(this).prop("disabled", true);
                $(this).prop("readonly", true);
            });
        }

        this.fwActionEnable = function ($elem) {
            // console.log("fwActionEnable");
            forElemAndChildren($elem, function () {
                var required = $(this).data("fw-required");
                if (required == 1) $(this).prop("required", true);
                $(this).prop("disabled", false);
                $(this).prop("readonly", false);
            });
        }

        this.fwActionRemove = function ($elem) {
            // Remove name attribute and hide this $elem and search for child inputs as well
            $elem.hide();

            forElemAndChildren($elem, function () {
                var name = $(this).attr("name") || "";
                if (name.length) $(this).attr("data-fw-restore-name", name).removeAttr("name");
            });

            forElemAndChildren($elem, function () {
                var required = $(this).prop("required") || false;
                if (required) $(this).attr("data-fw-required", 1).prop("required", false);
            });
        }

        this.fwActionRestore = function ($elem) {
            // Restore name attribute and show this $elem and search for child inputs as well
            forElemAndChildren($elem, function () {
                var name = $(this).attr("data-fw-restore-name") || "";
                if (name.length) $(this).attr("name", name).removeAttr("data-fw-restore-name");
            });

            forElemAndChildren($elem, function () {
                var required = $(this).data("fw-required");
                if (required == 1) $(this).prop("required", true);
            });

            $elem.show();
        }


        /* Task functions */

        this.fwTaskChange = function ($obj, $el, $tg) {

            // console.log("fwTaskChange");

            var action = $el.data("fw-action").split("|"),
                condition = $el.data("fw-condition").split("|");

            // Fill condition array, if smaller than action array
            if (action.length > condition.length) {
                var diff = action.length - condition.length;
                for (var i = 0; i < diff; i++) {
                    condition.push("!" + condition[condition.length - 1]);
                }
            }

            // Fill action array, if smaller than condition array
            if (condition.length > action.length) {
                var diff = condition.length - action.length;
                for (var i = 0; i < diff; i++) {
                    action.push(action[action.length - 1]);
                }
            }

            // Check the conditions and do correct action
            $.each(condition, function (k, condition) {
                if (checkCondition($tg, condition)) {
                    $.each(action[k].split(","), function (k1, action) {
                        var actionFnc = "fwAction" + ucfirst(action);

                        // Check Function Exists
                        if (typeof that[actionFnc] === "function") {
                            that[actionFnc]($el);
                        } else if (typeof actionFnc === "function") {
                            // Function is out of the Box
                            actionFnc($el);
                        }

                    });
                }
            });

        }

        /**
         * This depends on math.js (http://mathjs.org)
         */
        this.fwTaskCalc = function ($target) {

            var selectors = $target.data("fw-calc-selectors");
            var equation = $target.data('fw-calc');

            $.each(selectors, function (k, selector) {

                var value = parseFloat($(that).find(selector).val());
                if (isNaN(value)) value = 0;
                equation = equation.replace("(" + selector + ")", value.toString());

            });

            $target.val(math.eval(equation));

        }

        /* Add listeners */

        var that = this;

        this.init = function () {

            that.each(function (i) {

                var $obj = $(that);

                // // console.log($obj.find('[data-fw-target]'));

                $obj.find('[data-fw-equal]').each(function () {

                    var $elem = $(this),
                        target = $elem.data("fw-equal"),
                        getFnc = $elem.data("fw-get"),
                        $target = $obj.find(target);

                    $target.on("change.fw keyup.fw", function () {

                        switch (getFnc) {
                            case "text":
                                if ($(this).is("select")) {
                                    var $tg = $(this).find("option:selected");
                                } else {
                                    var $tg = $(this)
                                }

                                var e = document.createElement('div');
                                e.innerHTML = $tg.text();
                                if (e.childNodes.length) {
                                    value = e.childNodes[0].nodeValue;
                                } else {
                                    value = "";
                                }

                                break;

                            default:
                                var value = $(this).val();
                                console.log("def", value);
                                break;
                        }

                        $elem.val(value).trigger("change");

                    }).trigger("change.fw");

                    // that[taskFnc]($obj, $elem, $target);

                });

                $obj.find('[data-fw-action]').each(function () {

                    var $elem = $(this),
                        target = $elem.data("fw-target"),
                        event = $elem.data("fw-event") || "change",
                        taskFnc = "fwTask" + ucfirst(event),
                        $target = $obj.find(target);

                    // console.log("FW", target, event, taskFnc);

                    $target.on(event + ".fw", function () {
                        that[taskFnc]($obj, $elem, $(this));
                    }).trigger(event + ".fw");

                    // that[taskFnc]($obj, $elem, $target);

                });


                $obj.find('[data-fw-calc]').each(function () {

                    var $target = $(this);
                    var formula = $target.data('fw-calc');

                    var selectorsRaw = formula.match(/\([^()]+?\)/g);
                    var selectors = [];

                    $.each(selectorsRaw, function (k, selector) {
                        var selector = selector.replace(/[()]/g, "");
                        selectors.push(selector);

                        $(selector).on('change keyup', function () {
                            that.fwTaskCalc($target);
                        });
                    });

                    $target.data("fw-calc-selectors", selectors);
                    that.fwTaskCalc($target);

                });

            });

        }

        this.reinit = function () {

            // console.log("reinit formwatcher");

            var $obj = $(that);

            $obj.find('[data-fw-target]').each(function () {

                var $elem = $(this),
                    target = $elem.data("fw-target"),
                    event = $elem.data("fw-event") || "change",
                    $target = $obj.find(target);

                $target.off(event + ".fw");

            });

            that.init();

        }

        this[call]();


        /* Helper functions */

        function forElemAndChildren($elem, executable) {

            $batch = $();
            if ($elem.is("input,textarea,select,button")) {
                $batch = $elem.add($elem.find("input,textarea,select,button"));
            } else {
                $batch = $elem.find("input,textarea,select,button");
            }

            $batch.each(executable);

        }

        function checkCondition($elem, condition) {

            if ($elem.is("[type=radio]:not(:checked)"))
                return false;

            var not = false;
            if (condition.charAt(0) == "!") {
                condition = condition.substring(1);
                not = true;
            }

            if (condition.charAt(0) == ":") {
                out = $elem.is(condition);
            }

            if (condition.indexOf("value=") === 0) {
                var value = condition.split("=").pop();
                if (value.indexOf("[or]") > -1) {
                    var values = value.split('[or]');
                    out = false;
                    $.each(values, function (k, v) {
                        if ($elem.val() == v) {
                            out = true;
                            return false;
                        }
                    });
                } else {
                    out = $elem.val() == value;
                }
            }

            if (condition.indexOf("value<") === 0) {
                var value = condition.split("<").pop();
                out = parseFloat($elem.val()) < parseFloat(value);
            }

            if (condition.indexOf("value>") === 0) {
                var value = condition.split(">").pop();
                out = parseFloat($elem.val()) > parseFloat(value);
            }

            if (condition.indexOf("value<=") === 0) {
                var value = condition.split("<=").pop();
                out = parseFloat($elem.val()) <= parseFloat(value);
            }

            if (condition.indexOf("value>=") === 0) {
                var value = condition.split(">=").pop();
                out = parseFloat($elem.val()) >= parseFloat(value);
            }

            if (condition.indexOf("length") === 0) {
                var length = condition.split("=").pop();
                out = ($elem.val() || "").length == length;
            }

            if (condition.indexOf("contains") === 0) {
                var contains = condition.split("=").pop();
                out = $elem.val().indexof(contains) > -1;
            }

            return not ? !out : out;

        }

        function ucfirst(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }


    }

}(jQuery));
